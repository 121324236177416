import ReactPaginate from 'react-paginate'
import React, { useEffect, useState, useRef } from 'react';


const Pagination = (props) => {
    const [ offset, setOffset ] = useState(0); // 何番目のアイテムから表示するか
    const perPage = props.numOfLine; // 1ページあたりに表示したいアイテムの数

    // クリック時のfunction
    const handlePageChange = (data) => {
        let page_number = data['selected']; // クリックした部分のページ数が{selected: 2}のような形で返ってくる
        setOffset(page_number*perPage); // offsetを変更し、表示開始するアイテムの番号を変更

    }

    var return_paginate_preview = (props.dataList.slice(offset, offset + perPage))
    return_paginate_preview.push(
        <tr><td colSpan={props.col_count}>
            <div class="center">
        <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            pageCount={Math.ceil(props.dataList.length/perPage)} // 全部のページ数。端数の場合も考えて切り上げに。
            marginPagesDisplayed={1} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
            pageRangeDisplayed={5} // アクティブなページを基準にして、そこからいくつページ数を表示するか
            onPageChange={handlePageChange} // クリック時のfunction
            containerClassName={'pagination'} // ページネーションであるulに着くクラス名
            subContainerClassName={'pages pagination'}
            pageClassName={"page-item"}
            breakClassName={"page-item"}
            pageLinkClassName={"page-link"}
            breakLinkClassName={"page-link"}
            activeClassName={'active'} // アクティブなページのliに着くクラス名
            previousClassName={'pagination__previous page-item'} // 「<」のliに着けるクラス名
            nextClassName={'pagination__next page-item'} // 「>」のliに着けるクラス名
            disabledClassName={'pagination__disabled'} // 使用不可の「<,>」に着くクラス名
        /></div></td></tr>)

    return (
        return_paginate_preview
    )
}

export default Pagination