import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useHistory  } from 'react-router-dom';
import '../App.css';
import testJson from '../test/testJson/device_list_test.json';
import { Storage } from 'aws-amplify';
import Amplify, {Auth} from 'aws-amplify';
import API_GATEWAY from "./hostname";
import loadimg from "../images/loading.gif";
import Pagination from './pagination/Pagination.js';

const getIdToken = () =>{
    return new Promise((resolve, reject) => {
        try{
            Auth.currentSession().then((data) => {
                return resolve(data.getIdToken().getJwtToken());
              });
        }
        catch(e){
            console.log('Not signed in');
            return reject('ID取得失敗')
        }  
    })
  
}

const _sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
async function getControlResults(token, control_id){
    // すぐに状態取得しないように1秒待つ
    var count = 0;
    var controlResult = "";
    var isSuccess = false;
    while(count < 10){
        await _sleep(1000)
        const statusUrl = API_GATEWAY + "/console/v1/devices/control-results/"+ control_id;
        const resultHeaders = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization':token
        };
        const resultMethod = "GET"
        await fetch(statusUrl,{method:resultMethod, headers:resultHeaders, mode:"cors"})
        .then((res)=> res.json())
        .then(data => {
            if(data["status"] == "success"){
                controlResult = data["status"];
                isSuccess = true;
            }else{
                controlResult = data["status"];
            }
        },[])
        .catch(err => {
            controlResult = err;
            })
        if(isSuccess){
            return controlResult;
        }
        count++;
    }
    
    return controlResult;
    
}

function Commonarea_list() {
    const [topicLinks, setTopicLinks] = useState();
    const [groupLinks, setGroupLinks] = useState();
    const [authType, setAuthType] = useState('');
    const [redisp, setRedisp] = useState(false);
    const [hotelName, setHotelName] = useState('');
    const [uplink, setUplink] = useState('');
    const [downlink, setDownlink] = useState('');
    const [registButton, setRegistButton] = useState(<button class="regist_button_right_2" style={{backgroundColor:"#bebebe"}} disabled>新規登録</button>);
    const [loading, setLoading] = useState(true);
    const [isOnlyCommonarea, setIsOnlyCommonarea] = useState(true);
    const [codeStatusLinks, setCodeStatusLinks] = useState([]);
    const [cardStatusLinks, setCardStatusLinks] = useState([]);
    const numOfLine = 10;
    const [accessGroupLink, setAccessGroupLink] = useState('')
    const [accessGroupLink_card, setAccessGroupLink_card] = useState('')
    const [pinCode, setPinCode] = useState('')
    const [cardCode, setCardCode] = useState('')
    const [pinSend, setPinSend] = useState(0);
    const [pinDelete, setPinDelete] = useState(0);
    const [cardSend, setCardSend] = useState(0);
    const [cardDelete, setCardDelete] = useState(0);
    const [requestID, setRequestID] = useState('')
    const [cardRequestID, setCardRequestID] = useState('')
    const isFirstRenderPinSend = useRef(false)
    const isFirstRenderCardSend = useRef(false)
    const isFirstRenderPinDelete = useRef(false)
    const isFirstRenderCardDelete = useRef(false)


    useEffect(() => {
        isAdmin();
        isFirstRenderPinSend.current = true
        isFirstRenderCardSend.current = true
        isFirstRenderPinDelete.current = true
        isFirstRenderCardDelete.current = true

        if(location.state){
            setHotelName(location.state.hotelName)
        }
        else{
            history.push({
                pathname: "/",
            });
        }
    },[])

    function getIsInvalidPincode(){
        const reg_pin = /[0-9]/;

        if(!pinCode){
            return "暗証番号を入力してください"
        }
        if(!reg_pin.test(pinCode) || pinCode.length < 4 || 8 < pinCode.length){
            return "暗証番号は4～8文字の半角数字にしてください"
        }
        if(!requestID){
            return "リスエストIDを入力してください"
        }
    }

    function getIsInvalidPincodeDelete(){
        const reg_pin = /[0-9]/;

        if(!requestID){
            return "リスエストIDを入力してください"
        }
        if(!reg_pin.test(requestID)){
            return "リスエストIDは半角数字にしてください"
        }
    }

    function getIsInvalidCard(){
        const reg_card = /[0-9a-zA-Z]/;

        if(!cardCode){
            return "カード番号を入力してください"
        }
        if(!reg_card.test(cardCode) || cardCode.length < 1 || 32 < cardCode.length){
            return "カード番号は1～32文字の半角英数字にしてください"
        }
        if(!cardRequestID){
            return "リスエストIDを入力してください"
        }
    }

    function getIsInvalidCardDelete(){
        const reg_pin = /[0-9]/;

        if(!cardRequestID){
            return "リスエストIDを入力してください"
        }
        if(!reg_pin.test(cardRequestID)){
            return "リスエストIDは半角数字にしてください"
        }
    }

    useEffect(() => {
        if (authType != ''){
            // 機器一覧のリンクを取得
            getIdToken()
            .then(result => {
                const token = result;
                getKeyLinks(token)
                .then(result => {
                    setTopicLinks(result.commonTopicList)
                    setGroupLinks(result.accessGroupList)
                    setAccessGroupLinkFunc(result.access_groups)
                    getSettingLinks(token, result.access_groups)
                    .then(result => {
                        setCodeStatusLinks(result.codeStatestable);
                        setCardStatusLinks(result.cardStatestable);
                        setLoading(false)
                    }).catch(error => {
                        console.error("登録状態リンク作成失敗")
                        setLoading(false)
                    })
                }).catch(error => {
                    console.error("機器一覧リンク作成失敗")
                    setLoading(false)
                })

            }).catch(error => {
                console.error("IDトークン取得失敗")
                setLoading(false)
            })
        }
    },[authType, redisp])

    const getKeyLinks = (token) =>{
        return new Promise((resolve, reject) => {
            try{
                const method = "GET";
                const headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':token
                    };
                var url = API_GATEWAY + "/console/v1/devices/"+ location.state.hotelID;

                fetch(url,
                {method:method, headers:headers})
                .then((res)=> res.json())
                    .then(data => {
                    console.log("鍵一覧取得のfetch実行完了")
                    var accessGroupList = [];
                    // GWがあるとき、共用部のみの暗証番号設定を出来ないようにする。
                    if(data.gws){
                        if(0 < data.gws.length){
                            setIsOnlyCommonarea(false);
                        }
                    }
                    var isAccessGroup = false;
                    if(data.access_groups){
                        if(0 < data.access_groups.length){
                            isAccessGroup = true;
                        }
                    }
                    if(isAccessGroup){
                        accessGroupList.push(
                            <tr>
                                <td class="table_border_white_down_thtd_left accessgroup_table_height" colspan={2}>
                                    　<label class="accessgroup_table_height">{data.access_groups[0].access_group_id}</label>
                                </td>
                                <td class="table_border_up_thtd_right accessgroup_table_height">
                                    　<label class="">{data.access_groups[0].name}</label>
                                </td>
                                <td class="table_border_updown_thtd_right center accessgroup_table_height" rowSpan={data.access_groups.length}>
                                    <div class="center_wrapper">
                                        {(authType == 'admin' || authType == 'installation') &&
                                        <button class="button" onClick={updateHandleClick.bind(this)}>更新</button>}
                                    </div>
                                </td>
                            </tr>
                        );

                        for(var i = 1;i<data.access_groups.length-1;i++){
                            accessGroupList.push(
                                <tr>
                                    <td class="table_border_white_updown_thtd_left accessgroup_table_height" colspan={2}>
                                        　<label class="accessgroup_table_height">{data.access_groups[i].access_group_id}</label>
                                    </td>
                                    <td class=" accessgroup_table_height">
                                        　<label class="">{data.access_groups[i].name}</label>
                                    </td>
                                </tr>
                            );
                        }
                        accessGroupList.push(
                            <tr>
                                <td class="table_border_white_up_thtd_left accessgroup_table_height" colspan={2}>
                                    　<label class="accessgroup_table_height">{data.access_groups[data.access_groups.length-1].access_group_id}</label>
                                </td>
                                <td class="table_border_down_thtd_right accessgroup_table_height">
                                    　<label class="">{data.access_groups[data.access_groups.length-1].name}</label>
                                </td>
                            </tr>
                        );
                    }
                    else{
                        accessGroupList.push(
                            <tr>
                                <td class="table_border_white_down_thtd_left accessgroup_table_height" colspan={2}>
                                    　<label class="accessgroup_table_height">　</label>
                                </td>
                                <td class="table_border_updown_thtd_right accessgroup_table_height">
                                    　<label class="">　</label>
                                </td>
                                <td class="table_border_updown_thtd_right center accessgroup_table_height" >
                                    <div class="center_wrapper">
                                        {(authType == 'admin' || authType == 'installation') &&
                                        <button class="button" onClick={updateHandleClick.bind(this)}>更新</button>}
                                    </div>
                                </td>
                            </tr>
                            );
                    }
                    var commonTopicList = [];
                    if(data.common_topic.uplink && data.common_topic.downlink){
                        if(data.common_topic.uplink.length == 0 && data.common_topic.downlink.length == 0){
                            // uplinkとdownlinkが無い場合、新規登録ボタンは有効化。
                            setRegistButton(
                                <button class="regist_button_right_2" onClick={editHandleClick.bind(this)}>新規登録</button>
                            );
                        }
                    }
                    else{
                        // uplinkとdownlink要素自体が無い場合も新規登録ボタン有効化
                        setRegistButton(
                            <button class="regist_button_right_2" onClick={editHandleClick.bind(this)}>新規登録</button>
                        );
                    }
                    setUplink(data.common_topic.uplink);
                    setDownlink(data.common_topic.downlink);
                    commonTopicList.push(
                        <table class="table_border overall_table">
                            <tr>
                                <td class="table_border_updown_thtd_left_titlenumber" style={{width:"110px"}}>
                                </td>
                                <td class="require"></td>
                                <td class="table_border_updown_thtd_left_title" style={{width:"240px"}}>
                                </td>
                                <td class="table_border_updown_thtd_left_title" >
                                </td>
                            </tr>
                            <tr>
                                <td class="table_border_white_down_thtd_left" colspan={2}>
                                    　uplink
                                </td>
                                <td class="table_border_up_thtd_right">
                                    　<label class="">{data.common_topic.uplink}</label>
                                </td>
                                <td class="table_border_updown_thtd_right" rowSpan={2}>
                                    {(authType == 'admin' || authType == 'installation') &&
                                    <button class="button" onClick={deleteHandleClick.bind(this)}>削除</button>}
                                </td>
                            </tr>
                            <tr>
                                <td class="table_border_white_up_thtd_left" colspan={2}>
                                    　downlink
                                </td>
                                <td class="table_border_down_thtd_right">
                                    　<label class="">{data.common_topic.downlink}</label>
                                </td>
                            </tr>
                        </table>
                        );
                    const list = {"accessGroupList":accessGroupList, "commonTopicList":commonTopicList, "access_groups":data.access_groups};
                    return resolve(list);
                    },[])
                    .catch(err => {
                    console.log("鍵一覧取得のfetch失敗")
                    alert("鍵一覧情報の取得に失敗しました")
                    return reject('鍵一覧取得失敗')
                    })
            }
            catch(e){
                console.log('ホテル一覧取得失敗');
                return reject('ホテル一覧取得失敗')
            }  
        })
      
    }

    const getSettingLinks = (token, access_groups) =>{
        return new Promise((resolve, reject) => {
            try{
                if(access_groups){
                    if(0 < access_groups.length){
                        for(var i in access_groups){

                        }
                    }
                }

                const method = "GET";
                const headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':token
                    };
                var url = API_GATEWAY + "/console/v1/devices/common-areas/"+ location.state.hotelID;
                fetch(url,
                {method:method, headers:headers})
                .then((res)=> res.json())
                    .then(result => {
                    const data = result.data;
                    console.log("共用部登録状態のfetch実行完了")
                    var codeStatestable = [];
                    var cardStatestable = [];
                    for(var i in data){
                        var regist_word = "未登録";
                        if(data[i].user_code){
                            regist_word = "登録";
                        }
                        var code_ag = [];
                        for(var key in access_groups){
                            // 一致するアクセスグループの検索
                            for(var j in data[i].user_code_ag){
                                if(access_groups[key].access_group_id == data[i].user_code_ag[j]){
                                    code_ag.push(access_groups[key].name);
                                    code_ag.push(<br />);
                                    break;
                                }
                            }
                        }
                        var card_ag = [];
                        for(var key in access_groups){
                            // 一致するアクセスグループの検索
                            for(var j in data[i].card_id_ag){
                                if(access_groups[key].access_group_id == data[i].card_id_ag[j]){
                                    card_ag.push(access_groups[key].name);
                                    card_ag.push(<br />);
                                    break;
                                }
                            }
                        }
                        if(regist_word == "登録"){
                            codeStatestable.push(
                                <tr>
                                    <td class="table_border_updown_thtd_left accessgroup_table_height" colspan={2}>
                                        　<label class="accessgroup_table_height">{data[i].request_id}</label>
                                    </td>
                                    <td class="table_border_updown_thtd_right accessgroup_table_height">
                                        　<label class="">{regist_word}</label>
                                    </td>
                                    <td class="table_border_updown_thtd_right accessgroup_table_height">
                                        　<label class="">{code_ag}</label>
                                    </td>
                                </tr>
                                );
                        }
                        if(data[i].card_id){
                            cardStatestable.push(
                                <tr>
                                    <td class="table_border_updown_thtd_left accessgroup_table_height" colspan={2}>
                                        　<label class="accessgroup_table_height">{data[i].request_id}</label>
                                    </td>
                                    <td class="table_border_updown_thtd_right accessgroup_table_height">
                                        　<label class="">{data[i].card_id}</label>
                                    </td>
                                    <td class="table_border_updown_thtd_right accessgroup_table_height">
                                    　<label class="">{card_ag}</label>
                                    </td>
                                </tr>
                                );
                        }
                    }
                    const list = {"codeStatestable":codeStatestable, "cardStatestable":cardStatestable};
                    return resolve(list);
                    },[])
                    .catch(err => {
                    console.log("共用部登録情報の取得に失敗しました")
                    alert("共用部登録情報の取得に失敗しました")
                    return reject('共用部登録取得失敗')
                    })
            }
            catch(e){
                console.log('ホテル一覧取得失敗');
                return reject('ホテル一覧取得失敗')
            }  
        })
      
    }
    function setAccessGroupLinkFunc(access_groups){
        try{
            var aglist = [];
            var card_aglist = [];
            // アクセスグループがあれば、アクセスグループを選択するプルダウンを表示。なければ何も表示しない
            if(access_groups){
                if(0 < access_groups.length){
                    for(var i in access_groups){
                        aglist.push(
                        <li class="li_hotels">
                            　<input class="select_access_group" id={access_groups[i].access_group_id} type="checkbox" /> {access_groups[i].name}
                        </li>
                        )
                        card_aglist.push(
                            <li class="li_hotels">
                                　<input class="select_card_access_group" id={access_groups[i].access_group_id} type="checkbox" /> {access_groups[i].name}
                            </li>
                        )
                    }
                }
                setAccessGroupLink(
                    <tr>
                        <td class="table_border_updown_thtd_left" colspan={2}> 
                            アクセスグループ
                        </td>
                        <td class="table_border_down_thtd_right" colspan="3">
                                {aglist}
                        </td>
                    </tr>
                )
                setAccessGroupLink_card(
                    <tr>
                        <td class="table_border_updown_thtd_left" colspan={2}> 
                            アクセスグループ
                        </td>
                        <td class="table_border_down_thtd_right" colspan="3">
                            {card_aglist}
                        </td>
                    </tr>
                )
            }
        }catch(e){
            alert("アクセスグループ情報取得中にエラーが起きました")
        }

    }

    // 暗証番号送信を押すたびに呼ばれる
    useEffect(()=>{
        if(isFirstRenderPinSend.current){
            isFirstRenderPinSend.current = false;
        }
        else{
            if (!window.confirm("暗証番号を変更します")){
                return;
            }
            var isInvalid = getIsInvalidPincode();
            if(isInvalid){
                alert(isInvalid)
            }
            else{
                getIdToken()
                .then(result => {
                    var access_groups = document.getElementsByClassName('select_access_group');
                    var select_access_group = []
                    if(access_groups){
                        for (var i = 0; i < access_groups.length; i++){
                            if (access_groups[i].checked){
                                select_access_group.push(Number(access_groups[i].id));
                            }
                        }
                    }
                    if(select_access_group.length < 1){
                        alert("アクセスグループを設定してください")
                        return;
                    }
                    const token = result;
                    const obj = {
                        "user_code": pinCode,
                        "access_groups": select_access_group
                    };
                    const method = "PUT";
                    const body = JSON.stringify(obj);
                    const headers = {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization':token
                    };
                    fetch(API_GATEWAY + '/console/v1/devices/common-areas/' + location.state.hotelID + "/user-codes/" + requestID,
                    {method:method, headers:headers, body:body, mode:"cors"})
                    .then((res)=> res.json())
                      .then(data => {
                        console.log("fetch実行完了")
                        // 制御結果取得
                        getControlResults(token, data["control_id"])
                        .then(controlResult => {
                            if(controlResult == "success"){
                                alert("暗証番号変更に成功しました。")
                                window.location.reload();
                            }else{
                                console.error("結果取得失敗"+controlResult)
                                alert("暗証番号変更に失敗しました。")
                            }
                        },[])

                      },[])
                      .catch(err => {
                        console.log("fetch失敗")
                        alert("暗証番号登録に失敗しました。")
                        })
                }).catch(error => {
                    console.error("pin送信時IDトークン取得失敗")
                    alert("暗証番号登録に失敗しました。")
                }
                )
            }
        }
    },[pinSend])

    // 暗証番号削除を押すたびに呼ばれる
    useEffect(()=>{
        if(isFirstRenderPinDelete.current){
            isFirstRenderPinDelete.current = false;
        }
        else{
            if (!window.confirm("暗証番号を削除します")){
                return;
            }
            var isInvalid = getIsInvalidPincodeDelete();
            if(isInvalid){
                alert(isInvalid)
            }
            else{
                getIdToken()
                .then(result => {
                    const token = result;
                    const method = "DELETE";
                    const headers = {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization':token
                    };
                    const pinDeleteUrl = API_GATEWAY + "/console/v1/devices/common-areas/"+ location.state.hotelID + "/user-codes/" + requestID;
                    fetch(pinDeleteUrl,
                    {method:method, headers:headers, mode:"cors"})
                    .then((res)=> res.json())
                        .then(data => {
                            console.log("fetch実行完了")
                            // 制御結果取得
                            getControlResults(token, data["control_id"])
                            .then(controlResult => {
                                if(controlResult == "success"){
                                    alert("暗証番号削除に成功しました。")
                                    window.location.reload();
                                }else{
                                    console.error("結果取得失敗"+controlResult)
                                    alert("暗証番号削除に失敗しました。")
                                }
                            },[])
                        },[])
                        .catch(err => {
                        console.log("fetch失敗")
                        alert("暗証番号の削除に失敗しました")
                        })
                }).catch(error => {
                    console.error("pin削除送信時IDトークン取得失敗")
                }
                )
            }

        }
    },[pinDelete])

    // カード番号送信を押すたびに呼ばれる
    useEffect(()=>{
        if(isFirstRenderCardSend.current){
            isFirstRenderCardSend.current = false;
        }
        else{
            if (!window.confirm("カード番号を変更します")){
                return;
            }
            var isInvalid = getIsInvalidCard();
            if(isInvalid){
                alert(isInvalid)
            }
            else{
                getIdToken()
                .then(result => {
                    var access_groups = document.getElementsByClassName('select_card_access_group');
                    var select_access_group = []
                    if(access_groups){
                        for (var i = 0; i < access_groups.length; i++){
                            if (access_groups[i].checked){
                                select_access_group.push(Number(access_groups[i].id));
                            }
                        }
                    }
                    if(select_access_group.length < 1){
                        alert("アクセスグループを設定してください")
                        return;
                    }
                    const token = result;
                    const obj = {
                        "card_id": cardCode,
                        "access_groups": select_access_group
                    };
                    const method = "PUT";
                    const body = JSON.stringify(obj);
                    const headers = {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization':token
                    };
                    fetch(API_GATEWAY + '/console/v1/devices/common-areas/' + location.state.hotelID + "/cards/" + cardRequestID,
                    {method:method, headers:headers, body:body, mode:"cors"})
                    .then((res)=> res.json())
                        .then(data => {
                        console.log("fetch実行完了")
                        // 制御結果取得
                        getControlResults(token, data["control_id"])
                        .then(controlResult => {
                            if(controlResult == "success"){
                                alert("カード番号変更に成功しました。")
                                window.location.reload();
                            }else{
                                console.error("結果取得失敗"+controlResult)
                                alert("カード番号変更に失敗しました。")
                            }
                        },[])

                        },[])
                        .catch(err => {
                        console.log("fetch失敗")
                        alert("カード番号登録に失敗しました。")
                        })
                }).catch(error => {
                    console.error("カード番号送信時IDトークン取得失敗")
                    alert("カード番号登録に失敗しました。")
                }
                )
            }
        }
    },[cardSend])

    // カード番号削除を押すたびに呼ばれる
    useEffect(()=>{
        if(isFirstRenderCardDelete.current){
            isFirstRenderCardDelete.current = false;
        }
        else{
            if (!window.confirm("カード番号を削除します")){
                return;
            }
            var isInvalid = getIsInvalidCardDelete();
            if(isInvalid){
                alert(isInvalid)
            }
            else{
                getIdToken()
                .then(result => {
                    const token = result;
                    const method = "DELETE";
                    const headers = {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization':token
                    };
                    const cardDeleteUrl = API_GATEWAY + "/console/v1/devices/common-areas/"+ location.state.hotelID + "/cards/" + cardRequestID;
                    fetch(cardDeleteUrl,
                    {method:method, headers:headers, mode:"cors"})
                    .then((res)=> res.json())
                        .then(data => {
                            console.log("fetch実行完了")
                            // 制御結果取得
                            getControlResults(token, data["control_id"])
                            .then(controlResult => {
                                if(controlResult == "success"){
                                    alert("カード番号削除に成功しました。")
                                    window.location.reload();
                                }else{
                                    console.error("結果取得失敗"+controlResult)
                                    alert("カード番号削除に失敗しました。")
                                }
                            },[])
                        },[])
                        .catch(err => {
                        console.log("fetch失敗")
                        alert("カード番号の削除に失敗しました")
                        })
                }).catch(error => {
                    console.error("カード番号削除送信時IDトークン取得失敗")
                }
                )
            }

        }
    },[cardDelete])

    const history = useHistory();
    const location = useLocation();

    async function isAdmin(){
        try{
            const user = await Auth.currentUserPoolUser();
            const attrs = await Auth.userAttributes(user);
            attrs.forEach(function(attr){
                if (attr.Name == 'custom:authority'){
                    setAuthType(attr.Value);
                }
            });
        }
        catch(e){
            Auth.signOut();
        }
    }

    async function deleteHandleClick(e: React.MouseEvent){
        e.preventDefault(); // フォーム送信を抑止
        const lock_id = e.target.id;
        if (!window.confirm("共用部情報を削除しますか？")){
            return;
        }
        getIdToken()
        .then(result => {
            const method = "DELETE";
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':result
            };
            const deleteLockUrl = API_GATEWAY + "/console/v1/devices/common/"+location.state.hotelID;
            fetch(deleteLockUrl,
            {method:method, headers:headers})
            .then(res => {
                if (res.ok){
                    console.log("fetch実行完了")
                    // リストを再設定
                    setRedisp(!redisp);
                    return true;
                }else{
                    console.log("エラー："+res.status)
                    alert("削除中にエラーが起きました")
                }})
                .catch(err => {
                    console.log("fetch失敗")
                    alert("削除中にエラーが起きました")
                })
        }).catch(error => {
            console.error("IDトークン取得失敗")
        });
        return false;
    }

    function editHandleClick(){
        history.push({
          pathname: "/edit_commonarea",
          state: {
              hotelName: location.state.hotelName,
              hotelID: location.state.hotelID,
              contractorID: location.state.contractorID
          }
      });
    }
    const pinCodeHandleChange = (e) => {
        setPinCode(() => e.target.value)
    }
    const cardCodeHandleChange = (e) => {
        setCardCode(() => e.target.value)
    }
    const requestIDHandleChange = (e) => {
        setRequestID(() => e.target.value)
    }
    const cardRequestIDHandleChange = (e) => {
        setCardRequestID(() => e.target.value)
    }

    function updateHandleClick(){
        getIdToken()
        .then(result => {
            const token = result;
            update(token)
            .then(data => {
                // 画面再描画
                setRedisp(!redisp);
                alert("共用部アクセスグループを更新いたしました。")
            }).catch(error => {
                console.error("共用部アクセスグループ更新失敗")
            }
            )
        }).catch(error => {
            console.error("更新時IDトークン取得失敗")
        }
        )
    }
    const update = (token) =>{
        console.log("共用部アクセスグループ更新開始")
        return new Promise((resolve, reject) => {
            try{
                const updateMethod = "PUT";
                const updateHeaders = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':token
                };
                const updateUrl = API_GATEWAY + "/console/v1/devices/common/" + location.state.hotelID + "/update"
                // 共用部アクセスグループを更新
                fetch(updateUrl,
                {method:updateMethod, headers:updateHeaders, mode:"cors"})
                .then((res)=> res.json())
                    .then(data => {
                        console.log("共用部アクセスグループ更新のfetch実行完了")
                        return resolve(data);
                    },[])
                    .catch(err => {
                    alert("共用部アクセスグループ情報の更新に失敗しました")
                })
            }
            catch(e){
                console.log('Not signed in');
                return reject('共用部アクセスグループ更新失敗')
            }  
        })
    }

    if (loading){
        return (

        <div class="center_parents">
            <table class="overall_table">
                <tr>
                    <td>
                        <div class="title_text">共用部設定　＜{hotelName}＞</div>
                    </td>
                    <td>
                    {(authType == 'admin' || authType == 'installation')&&
                        registButton
                    }
                    </td>
                </tr>
            </table>

            <hr color="#00B4C6" size="6" />
            <br />
            <table class="table_border overall_table">
                <tr>
                    <td>
                        <div>
                        <img src={loadimg}/>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        );
    }
    return (
        <div class="">
            <table class="overall_table">
                <tr>
                    <td>
                        <div class="title_text">共用部設定　＜{hotelName}＞</div>
                    </td>
                    <td>
                    {(authType == 'admin' || authType == 'installation')&&
                        registButton
                    }
                    </td>
                </tr>
            </table>

            <hr color="#00B4C6" size="6" />
            <br />
            <div class="title_text">状態</div>            
            {topicLinks}
            <br />
            <div class="title_text">アクセスグループ</div>
            <table class="table_border overall_table">
                <tr>
                    <td class="table_border_updown_thtd_left_titlenumber" style={{width:"110px"}}>
                        　ID
                    </td>
                    <td class="require"></td>
                    <td class="table_border_updown_thtd_left_title" style={{width:"240px"}}>
                        　グループ名
                    </td>
                    <td class="table_border_updown_thtd_left_title" >
                    </td>
                </tr>
                {groupLinks}
            </table>
            <br />


            {isOnlyCommonarea &&
            <div>
            <div class="title_text">暗証番号</div>
            <table class="table_border overall_table">
                <tr>
                    <td class="table_border_updown_thtd_left_titlenumber" style={{width:"110px"}}>
                        　リクエストID
                    </td>
                    <td class="require"></td>
                    <td class="table_border_updown_thtd_left_title" style={{width:"280px"}}>
                        　暗証番号
                    </td>
                    <td class="table_border_updown_thtd_left_title" >
                        　アクセスグループ
                    </td>
                </tr>
                <Pagination
                dataList={codeStatusLinks}
                col_count={4}
                numOfLine={numOfLine}
                />
            </table>
            <br />

            <div class="title_text">カード番号</div>
            <table class="table_border overall_table">
                <tr>
                    <td class="table_border_updown_thtd_left_titlenumber" style={{width:"110px"}}>
                        　リクエストID
                    </td>
                    <td class="require"></td>
                    <td class="table_border_updown_thtd_left_title" style={{width:"280px"}}>
                        　カード番号
                    </td>
                    <td class="table_border_updown_thtd_left_title" >
                        　アクセスグループ
                    </td>
                </tr>
                <Pagination
                dataList={cardStatusLinks}
                col_count={4}
                numOfLine={numOfLine}
                />
            </table>
            <br />

            <div class="title_text">暗証番号登録</div>
            <table class="table_border">
                <tr>
                    <td class="td_width_settingdoorlock_title"></td>
                    <td class="require"></td>
                    <td class="td_width_settingdoorlock_textbox"></td>
                    <td></td>
                </tr>
                <tr>
                    <td class="table_border_updown_thtd_left">
                        <label class="li_left">リクエストID</label>
                    </td>
                    <td class="table_border_updown_thtd_left require">
                        必須
                    </td>
                    <td class="table_border_up_thtd_right">
                        <input class="textbox li_left" style={{width:"300px"}} type="text" value={requestID} onChange={requestIDHandleChange}/>
                    </td>
                    <td class="table_border_up_thtd_right" rowSpan={2}>
                        <button class="button " onClick={() => setPinSend(pinSend+1)}>送信</button>
                        <button class="button " onClick={() => setPinDelete(pinDelete+1)}>削除</button>
                    </td>
                    <td class="table_border_up_thtd_right">
                    </td>
                </tr>
                <tr>
                    <td class="table_border_updown_thtd_left" colspan={2}>
                        <label class="li_left">暗証番号</label>
                    </td>
                    <td class="">
                        <input class="textbox li_left" style={{width:"300px"}} type="text" value={pinCode} onChange={pinCodeHandleChange}/>
                    </td>
                    <td class="">
                    </td>
                </tr>
                {accessGroupLink}
            </table>
            <br />
            <div class="title_text">カード番号登録</div>
            <table class="table_border">
                <tr>
                    <td class="td_width_settingdoorlock_title"></td>
                    <td class="require"></td>
                    <td class="td_width_settingdoorlock_textbox"></td>
                    <td></td>
                </tr>
                <tr>
                    <td class="table_border_updown_thtd_left">
                        <label class="li_left">リクエストID</label>
                    </td>
                    <td class="table_border_updown_thtd_left require">
                        必須
                    </td>
                    <td class="table_border_up_thtd_right">
                        <input class="textbox li_left" style={{width:"300px"}} type="text" value={cardRequestID} onChange={cardRequestIDHandleChange}/>
                    </td>
                    <td class="table_border_up_thtd_right" rowSpan={2}>
                        <button class="button " onClick={() => setCardSend(cardSend+1)}>送信</button>
                        <button class="button " onClick={() => setCardDelete(cardDelete+1)}>削除</button>
                    </td>
                    <td class="table_border_up_thtd_right">
                    </td>
                </tr>
                <tr>
                    <td class="table_border_updown_thtd_left" colspan={2}>
                        <label class="li_left">カード番号</label>
                    </td>
                    <td class="">
                        <input class="textbox li_left" style={{width:"300px"}} type="text" value={cardCode} onChange={cardCodeHandleChange}/>
                    </td>

                </tr>
                {accessGroupLink_card}
            </table>
            </div>}
            <br />
        </div>
    );
}

export default Commonarea_list;
