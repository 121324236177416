import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import '../App.css';
import testJson from '../test/testJson/contractorTest.json';
import Amplify, {Auth} from 'aws-amplify';
import API_GATEWAY from "./hostname";
import {AuthState, onAuthUIStateChange} from "@aws-amplify/ui-components";
import Pagination from './pagination/Pagination.js';
import loadimg from "../images/loading.gif";

const getIdToken = () =>{
    return new Promise((resolve, reject) => {
        try{
            Auth.currentSession().then((data) => {
                return resolve(data.getIdToken().getJwtToken());
              });
        }
        catch(e){
            console.log('Not signed in');
            return reject('ID取得失敗')
        }  
    })
  
}
function Error_status(){
    const [links, setLinks] = useState([]);
    const [authType, setAuthType] = useState('');
    const [contractorList, setContractorList] = useState([]);
    const location = useLocation();
    const [redisp, setRedisp] = useState(false);
    const numOfLine = 10;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        isAdmin();
        // サインイン完了後のコールバック
        onAuthUIStateChange((nextAuthState, authData) => {
            if (nextAuthState == AuthState.SignedIn){
                if (authType == ''){
                    isAdmin();
                }
            }
        });
    },[])
    useEffect(() => {
        if (authType != ''){
            // エラー一覧のリンクを取得
            getErrorStatus()
                    .then(result => {
                        setLinks(result)
                        setLoading(false)
                    }).catch(error => {
                        console.error("エラー一覧リンク作成失敗:"+error)
                        setLoading(false)
                    })
        }
    },[authType, redisp])

    const getErrorStatus = () =>{
        return new Promise((resolve, reject) => {
            try{
                getIdToken()
                .then(result => {
                    const method = "GET";
                    const headers = {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization':result
                      };
                    fetch(API_GATEWAY + '/console/v1/devices/errors',
                    {method:method, headers:headers})
                    .then((res)=> res.json())
                      .then(data => {
                        console.log("エラー一覧取得のfetch実行完了")

                        // ソート（ホテル順/同ホテルは時間最新順）
                        data.errors.sort(function(a,b){
                            if(a.hotel_name == b.hotel_name){
                                if(a.time < b.time) return 1;
                                if(a.time > b.time) return -1;
                            }
                            if(a.hotel_name < b.hotel_name) return -1;
                            if(a.hotel_name > b.hotel_name) return 1;
                            return 0;
                        });

                        var list = [];
                        for(var i in data.errors){
                            if (authType != 'admin' && !contractorList.includes(data.errors[i].contractor_id)){
                                continue;
                            }
                            var event = data.errors[i].event;
                            var error_txt = "";
                            if(event == "low_battery"){
                                error_txt="電池残量（少）"
                            }
                            else if(event == "low_battery_critical"){
                                error_txt="電池残量（致命）"
                            }
                            else if(event == "deadbolt_jammed"){
                                error_txt="デッドボルト詰まり"
                            }
                            else if(event == "tamper"){
                                error_txt="こじ開け"
                            }
                            else if(event == "3min_lock"){
                                error_txt="3分ロック"
                            }
                            else{
                                error_txt=event;
                            }
                            list.push(
                                <tr>
                                    <td class="table_border_updown_thtd_left_number">
                                        {Number(i)+1}
                                    </td>
                                    <td class="table_border_updown_thtd_left">
                                        {data.errors[i].hotel_name}
                                    </td>
                                    <td class="table_border_updown_thtd_right">
                                        　{data.errors[i].floor}
                                    </td>
                                    <td class="table_border_updown_thtd_right">
                                        {data.errors[i].room_name}
                                    </td>
                                    <td class="table_border_updown_thtd_right">
                                        {error_txt}
                                    </td>
                                    <td class="table_border_updown_thtd_right">
                                        {data.errors[i].time.slice(0, 19).replace('T', ' ').replace(/-/g, '/')}
                                    </td>
                                    {authType == 'admin' && 
                                    <td class="table_border_updown_thtd_right">
                                        <button class="button" id={data.errors[i].error_id} onClick={deleteHandleClick.bind(this, data.errors[i].error_id, i)}>削除</button>
                                    </td>}
                                </tr>
                            );
                        }
                        // ページネーション用。リストの数が１ページ表示行数より多い時の処理。
                        if(list){
                            if(numOfLine < list.length){
                                const amari = list.length % numOfLine;
                                const blank_tr_count = numOfLine - amari;
                                if(amari != 0){
                                    for(var l=0;l<blank_tr_count;l++){
                                        list.push(<tr></tr>)
                                    }   
                                }
                            }
                        }
                        return resolve(list);
                      },[])
                      .catch(err => {
                        console.log("エラー一覧取得のfetch失敗")
                        alert("エラー一覧の取得に失敗しました")
                        return reject('エラー一覧取得失敗')
                        })
                }).catch(error => {
                    console.error("IDトークン取得失敗")
                    return reject('IDトークン取得失敗')
                })
            }
            catch(e){
                console.log('エラー一覧取得失敗');
                return reject('エラー一覧取得失敗')
            }  
        })
      
    }

    const history = useHistory();

    async function isAdmin(){
        try{
            const user = await Auth.currentUserPoolUser();
            const attrs = await Auth.userAttributes(user);
            // 先にhotelListをsetして次のstateでauthをsetする
            attrs.forEach(function(attr){
                if (attr.Name == 'custom:contractors'){
                    const contractors = attr.Value.split(/\s/);
                    setContractorList(contractors);
                }
            });
            attrs.forEach(function(attr){
                if (attr.Name == 'custom:authority'){
                    setAuthType(attr.Value);
                }
            });
        }
        catch(e){
            /*
            現状、トークン無効エラーでのみここに来るので無条件でサインアウトする。
            今後条件判定が必要であれば、e.code=="NotAuthorizedException"などで見ればよい。
            {code: 'NotAuthorizedException', name: 'NotAuthorizedException', message: 'Access Token has been revoked'}
            */
            Auth.signOut();
        }
    }

    function deleteHandleClick(error_id, number){
        const error_num = Number(number) + 1;
        if (!window.confirm("エラーNo."+ error_num + "を削除しますか？")){
            return;
        }
        getIdToken()
        .then(result => {
            const method = "DELETE";
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':result
            };
            const deleteErrorUrl = API_GATEWAY + "/console/v1/devices/errors/"+error_id;
            fetch(deleteErrorUrl,
            {method:method, headers:headers})
            .then(res => {
                if (res.ok){
                    console.log("fetch実行完了")
                    // リストを再設定
                    setRedisp(!redisp);
                    return true;
                }else{
                    console.log("エラー")
                    alert("削除中にエラーが起きました")
                }})
                .catch(err => {
                    console.log("fetch失敗")
                    alert("削除中にエラーが起きました")
                })
        }).catch(error => {
            console.error("IDトークン取得失敗")
        });
        return false;
    }


    if (loading){
        return (

        <div class="center_parents">
            <table class="overall_table">
                <tr>
                    <td>
                        <div class="title_text">エラー状況</div>
                    </td>
                </tr>
            </table>

            <hr color="#00B4C6" size="6" />
            <br />
            <table class="table_border overall_table">
                <tr>
                    <td>
                        <div>
                        <img src={loadimg}/>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        );
    }
    return (
        
        <div class="center_parents">
            <div class="title_text">エラー状況</div>
            <hr color="#00B4C6" size="6" />
            <br />
            <table class="table_border overall_table">
                <tr>
                    <td class="table_border_updown_thtd_left_titlenumber">
                        No.
                    </td>
                    <td class="table_border_updown_thtd_left_title" style={{width:250}}>
                        　ホテル
                    </td>
                    <td class="table_short_title">
                        　フロア
                    </td>
                    <td class="table_short_title">
                        部屋番号
                    </td>
                    <td class="table_short_title">
                        エラー内容
                    </td>
                    <td class="table_short_title_2">
                        発生日時
                    </td>
                    <td class="table_short_title_button">

                    </td>
                </tr>
                <Pagination
                dataList={links}
                col_count={7}
                numOfLine={numOfLine}
                />
                </table>
        </div>
    );
}
export default Error_status;