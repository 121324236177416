import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import '../App.css';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from "../aws-exports";
import AWS from 'aws-sdk';
import getCognitoProvider from "./cognite_common";
import Pagination from './pagination/Pagination.js';
import loadimg from "../images/loading.gif";
import {AuthState, onAuthUIStateChange} from "@aws-amplify/ui-components";

// Amplify from aws-exports
Amplify.configure(awsconfig);
// Auth from aws-exports
Auth.configure(awsconfig);

function Usermanagement() {
    const [users, setUsers] = useState([]);
    const [redisp, setRedisp] = useState(false);
    const [admFlag, setAdmFlag] = useState(false);
    const history = useHistory();
    const numOfLine = 10;
    const [loading, setLoading] = useState(true);

    async function isAdmin(){
        const user = await Auth.currentUserPoolUser();
        const attrs = await Auth.userAttributes(user);
        attrs.forEach(function(attr){
            if (attr.Name == 'custom:authority'){
                if(attr.Value == 'admin'){
                    setAdmFlag(true);
                    setLoading(false)
                }
                // admin以外はこの画面に遷移させない
                else{
                    history.push({
                        pathname: "/",
                    });
                }
            }
        });
    }

    useEffect(() => {
        isAdmin();
        // サインイン完了後のコールバック
        onAuthUIStateChange((nextAuthState, authData) => {
            if (nextAuthState == AuthState.SignedIn){
                if (!admFlag){
                    isAdmin();
                }
            }
        });
    },[])

    useEffect(() => {
        // 管理者権限がある場合はユーザー一覧を取得
        if(admFlag){
            getUserLists()
            .then(result => {
                setUsers(result)
            }).catch(error => {
                console.error("ユーザー一覧作成失敗")
            })
        }
    },[admFlag, redisp])

    useEffect(() => {
        // 管理者権限の有無を取得時リストを再設定
        setRedisp(!redisp);
    },[admFlag])

    function editHandleClick(name, customname){
        history.push({
          pathname: "/regist_user",
          state: {
            name: name,
            customname: customname
          }
        });
    }
    async function deleteHandleClick(del_user){
        // e.preventDefault(); // フォーム送信を抑止
        const name = del_user.Username;
        const attrs = del_user.Attributes;
        var clientId = null;
        for(var i in attrs){
            if (attrs[i].Name == "custom:app_client_id"){
                clientId = attrs[i].Value;
            }
        }
        const confirmed = window.confirm(name + "を削除しますか？");
        let result = false;
        if (confirmed){
            result = await deleteUser(name, clientId);
        }
        if (result){
            // リストを再設定
            setRedisp(!redisp);
        }
    }
    async function deleteUser(userName, clientId) {
        try {
            const [cognitoServiceProvider, user] = await getCognitoProvider();
            if(clientId){
                const del_userpoolclient_result = await cognitoServiceProvider.deleteUserPoolClient({
                    "ClientId":clientId,
                    "UserPoolId":user.pool.userPoolId
                }).promise();
            }
            const result = await cognitoServiceProvider.adminDeleteUser({
                UserPoolId: user.pool.userPoolId,
                Username: userName
            }).promise();
            alert(userName + "を削除しました。");
        } catch (error) {
            alert("削除に失敗しました");
            return false;
        }
        return true;
    };

    async function getCognitoUsers() {
        const [cognitoServiceProvider, user] = await getCognitoProvider();
        // ユーザプール内のユーザを取得する (AWS認証情報がないと使えない部分)
        const result = await cognitoServiceProvider.listUsers({ UserPoolId: user.pool.userPoolId }).promise();
        return result.Users;
    }

    const getUserLists = () =>{
        return new Promise((resolve, reject) => {
            try{
                getCognitoUsers().then((cgusers) => {
                    // カスタム名ソート（文字辞書順）
                    cgusers.sort(function(a,b){
                        var name1 = a["Attributes"].find((element)=>{return element.Name == "custom:name"})
                        var name2 = b["Attributes"].find((element)=>{return element.Name == "custom:name"})
                        // カスタム名があれば比較
                        if(name1 && name2){
                            if(name1.Value < name2.Value) return -1;
                            if(name1.Value > name2.Value) return 1;
                        }
                        return 0;
                    });
                    var list = [];
                    for(var i in cgusers){
                        var name = "";
                        var nameData = cgusers[i]["Attributes"].find((element)=>{return element.Name == "custom:name"})
                        if(nameData){
                            name = nameData.Value
                        }else{
                            // カスタムユーザー名が取得できない時はメールアドレスを表示
                            name = cgusers[i].Username;
                        }
                        list.push(
                        <tr>
                            <td class="table_border_updown_thtd_left_number">
                                {Number(i)+1}
                            </td>
                            <td class="table_border_updown_thtd_left">
                                <label class="li_left">{name}</label>
                            </td>
                            <td class="table_border_updown_thtd_right center">
                                <label class="li_left">{cgusers[i].Username}</label>
                            </td>
                            <td class="table_border_updown_thtd_right">
                                <button class="button" onClick={editHandleClick.bind(this, cgusers[i].Username, name)}>編集</button>
                                <button class="button" type="submit" id={cgusers[i].Username} value="削除" onClick={deleteHandleClick.bind(this, cgusers[i])}>削除</button>
                            </td>

                        </tr>);
                    }
                    // ページネーション用。リストの数が１ページ表示行数より多い時の処理。
                    if(list){
                        if(numOfLine < list.length){
                            const amari = list.length % numOfLine;
                            const blank_tr_count = numOfLine - amari;
                            if(amari != 0){
                                for(var l=0;l<blank_tr_count;l++){
                                    list.push(<tr></tr>)
                                }   
                            }
                        }
                    }
                    return resolve(list);
                });
            }
            catch(e){
                return reject('取得失敗');
            }
        })
    }

    if (loading){
        return (

        <div class="center_parents">
            <table class="overall_table">
                <tr>
                    <td>
                        <div class="title_text">ユーザー管理</div>
                    </td>
                    <td>
                        <Link　class="regist_button_right_1" to={`/regist_user`}>新規登録</Link>
                    </td>
                </tr>
            </table>

            <hr color="#00B4C6" size="6" />
            <br />
            <table class="table_border overall_table">
                <tr>
                    <td>
                        <div>
                        <img src={loadimg}/>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        );
    }
    return (
        <div class="center_parents">
            <table class="overall_table">
                <tr>
                    <td>
                        <div class="title_text">ユーザー管理</div>
                    </td>
                    <td>
                        <Link　class="regist_button_right_1" to={`/regist_user`}>新規登録</Link>
                    </td>
                </tr>
            </table>

            <hr color="#00B4C6" size="6" />
                <table class="table_border overall_table">
                    <tr>
                        <td class="table_border_updown_thtd_left_titlenumber">
                            No.
                        </td>
                        <td class="table_username_title">
                            ユーザー名
                        </td>
                        <td class="table_border_updown_thtd_left_title center">
                            メールアドレス
                        </td>
                    </tr>
                    <Pagination
                    dataList={users}
                    col_count={4}
                    numOfLine={numOfLine}
                    />
                </table>
        </div>
    );
}

export default Usermanagement;
