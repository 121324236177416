import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Setting_doorlock from './templates/setting_doorlock';
import Setting_gateway from './templates/setting_gateway';
import Hotellist from './templates/hotellist';
import Edit_hotel from './templates/edit_hotel';
import Regist_hotel from './templates/regist_hotel';
import Device_list from './templates/device_list';
import Doorlock_list from './templates/doorlock_list';
import Gw_list from './templates/gw_list';
import Dsk_list from './templates/dsk_list';
import Commonarea_list from './templates/commonarea_list';
import Edit_commonarea from './templates/edit_commonarea';
import Contractor from './templates/contractor';
import Status from './templates/status';
import Doorlockregistration from './templates/doorlockregistration';
import Regist_user from './templates/regist_user';
import Regist_contractor from './templates/regist_contractor';
import Usermanagement from './templates/usermanagement';
import Edit_contractor from './templates/edit_contractor';
import Change_password from './templates/change_password';
import Error_status from './templates/error_status';
import './App.css';
import { Link, useHistory } from 'react-router-dom';
import { AmplifyAuthenticator, AmplifySignIn, AmplifyConfirmSignUp, AmplifyForgotPassword} from "@aws-amplify/ui-react";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import { Auth, I18n } from 'aws-amplify';
import store from "./store/index";
import { connect, useSelector  } from "react-redux";
import { useEffect, useState } from 'react';
import {AuthState, onAuthUIStateChange} from "@aws-amplify/ui-components";
import lead_icon from "./images/lead_icon.png";

// 以下のような形で日本語と英語を紐づけた辞書を作成する
const dict = {
  ja: {
    "Forgot your password?": "パスワードを忘れた方は",
    "Reset password": "こちら",
    "Reset your password": "パスワードの再設定",
    "Enter your username": "メールアドレスを入力してください",
    "Back to Sign In": "ログイン画面へ戻る",
    "Verification code": "認証コード",
    "New password": "再設定するパスワード",
    "Enter code": "認証コードを入力してください",
    "New password": "再設定パスワード",
    "Enter your new password": "再設定するパスワードを入力してください",
    "Username *": "再設定メールアドレス",
    'User does not exist.': 'ユーザーが存在しません',
    'Confirm Sign up': '認証再設定',
    'Confirmation Code': '認証コード',
    "Enter your code": "認証コードを入力してください",
    'Lost your code?': '認証メール',
    'Resend Code': '再送信',
    

    'Username/client id combination not found.': 'ユーザーが存在しません',
    'Incorrect username or password.': 'ユーザー名またはパスワードが違います',
    'User is not confirmed.': 'ユーザーは検証されていません',
    'User already exists': 'ユーザーは既に存在します',
    'Invalid verification code provided, please try again.': '指定された確認コードが無効です。もう一度お試しください',
    'Invalid password format': 'パスワードのフォーマットが不正です',
    'Account recovery requires verified contact information': 'アカウントの復元には確認済みの連絡先情報が必要です',
    'Invalid phone number format': '不正な電話番号フォーマットです。 電話番号は次のフォーマットで入力してください: +12345678900',
    'An account with the given email already exists.': 'そのメールアドレスは既に存在します',
    'Username cannot be empty': 'ユーザー名は必須です',
    'Password attempts exceeded': 'パスワード試行回数が超過しました',
    'Attempt limit exceeded, please try after some time.': '試行制限を超過しました。しばらくしてからもう一度お試しください',
    'Username/client id combination not found.': 'ユーザーが存在しません',
    'CUSTOM_AUTH is not enabled for the client.': 'パスワードは必須です', 
    'Password does not conform to policy: Password not long enough': 'パスワードは8文字以上を入力してください (8文字以上の大文字小文字を含む英数字)',
    'Password does not conform to policy: Password must have uppercase characters': 'パスワードには大文字を含めてください (8文字以上の大文字小文字を含む英数字)',
    'Password does not conform to policy: Password must have lowercase characters': 'パスワードには小文字を含めてください (8文字以上の大文字小文字を含む英数字)', 
    'Password does not conform to policy: Password must have numeric characters': 'パスワードには数字を含めてください (8文字以上の大文字小文字を含む英数字)', 
    "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6": 'パスワードは8文字以上、大文字小文字を含む英数字を指定してください', // 適宜修正。本来の意味とは異なるがこれで明示している。
    "2 validation errors detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6; Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\S]+.*[\S]+$": 'パスワードは8文字以上、大文字小文字を含む英数字を指定してください', 
    "Cannot reset password for the user as there is no registered/verified email or phone_number":'ユーザーが存在しません',
  },
};

// 作成した辞書を渡して反映させる
I18n.putVocabularies(dict);
I18n.setLanguage("ja");

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

function App({count}){
    const [admFlag, setAdmFlag] = useState(false);
    const [userName, setUserName] = useState();
    const [customName, setCustomName] = useState();
    const locks = useSelector((state) => state.locksReducer.locks);
    const history = useHistory();

    useEffect(() => {
        isAdmin();
        // サインイン完了後のコールバック
        onAuthUIStateChange((nextAuthState, authData) => {
            if (nextAuthState == AuthState.SignedIn){
                if (!userName){
                    isAdmin();
                }
            }
        });
    },[])
    async function isAdmin(){
        const user = await Auth.currentUserPoolUser();
        const attrs = await Auth.userAttributes(user);
        if(user){
          setUserName(user.username)
          setCustomName(user.attributes["custom:name"])
        }
        let adm = false;
        attrs.forEach(function(attr){
            if (attr.Name == 'custom:authority' && attr.Value == 'admin'){
                adm = true;
            }
        });
        setAdmFlag(adm);
    }

    

    return(
      <AmplifyAuthenticator>
        {/* <AmplifyAuthenticator 
          handleAuthStateChange={(state, data) => {
            console.log("state:"+state)
            console.log("data:"+data)
            // ログインした時
            if(state=="signedin"){
              // 必ず/に飛ばす
              window.location.href = '/';
            }
          }}>
        </AmplifyAuthenticator> */}

        <AmplifySignIn
        slot="sign-in"
        headerText="LeaLockサービス"
        submitButtonText="ログイン"
        hideSignUp={true}
        formFields={[
          {
            type: "username",
            label: "",
            placeholder: "ID",
            required: true,
          },
          {
            type: "password",
            label: "",
            placeholder: "Password",
            required: true,
          },
        ]}
      />

      <AmplifyForgotPassword
        slot="forgot-password"
        headerText="パスワードの再設定"
        usernameAlias="email"
        formFields={[
          {
            type: "email",
            label: "再設定メールアドレス",
            placeholder: "メールアドレスを入力してください",
          },
        ]}
        sendButtonText="送信"
        submitButtonText="設定"
      />
      <AmplifyConfirmSignUp
        submitButtonText="送信"
        slot="confirm-sign-up"
      />
        <BrowserRouter>
          <div class="overall">
            
            <table class="overall_table">
              <tr>
                <header class="header" >
                  <br></br>
                  <div class="header_title">LeaLock</div>
                  　<Link class="header_link" to={`/contractor`}>契約者一覧</Link>
                  {admFlag &&
                  <Link class="header_link" to={`/status`}>　利用状況</Link>}
                  {admFlag &&
                  <Link class="header_link" to={`/error_status`}>　エラー状況</Link>}
                  {admFlag &&
                  <Link class="header_link" to={`/usermanagement`}>　ユーザー一覧</Link>}
                  {/* {admFlag &&
                  <Link class="header_link" to={`/doorlockregistration`}>　ドアロック登録</Link>} */}
                  
                  <ul class="header_username">
                    <li>
                      <a>{customName}</a>
                      <ul>
                          {/* <li><a href=""><Link class="" to={`/regist_user`}>ユーザー情報</Link></a></li> */}
                          <li><a href=""><Link class="header_username_child" to={`/change_password`}>パスワード変更</Link></a></li>
                          <li><a href=""><Link class="header_username_child" onClick={() => Auth.signOut()}>ログアウト</Link></a></li>
                      </ul>
                    </li>
                  </ul>

                  {/* <p>値取得テスト :{store.getState().test_text}</p>
                  <p>Count: {count}</p>
                  <ul>
                    {locks.map((lock) => (
                    <li key={lock.id}>{lock.serialNumber}</li>
                    ))}
                  </ul> */}

                </header>
              </tr>
              <tr>
                <body class="body">
                    <Switch>
                      <Route exact path="/regist_contractor" component={Regist_contractor} />
                      <Route exact path="/edit_contractor" component={Edit_contractor} />
                      <Route exact path="/setting_doorlock" component={Setting_doorlock} />
                      <Route exact path="/setting_gateway" component={Setting_gateway} />
                      <Route exact path="/hotellist" component={Hotellist} />
                      <Route exact path="/edit_hotel" component={Edit_hotel} />
                      <Route exact path="/regist_hotel" component={Regist_hotel} />
                      <Route exact path="/device_list" component={Device_list} />
                      <Route exact path="/doorlock_list" component={Doorlock_list} />
                      <Route exact path="/gw_list" component={Gw_list} />
                      <Route exact path="/dsk_list" component={Dsk_list} />
                      <Route exact path="/commonarea_list" component={Commonarea_list} />
                      <Route exact path="/edit_commonarea" component={Edit_commonarea} />
                      <Route exact path="/contractor" component={Contractor} />
                      <Route exact path="/status" component={Status} />
                      <Route exact path="/doorlockregistration" component={Doorlockregistration} />
                      <Route exact path="/regist_user" component={Regist_user} />
                      <Route exact path="/usermanagement" component={Usermanagement} />
                      <Route exact path="/change_password" component={Change_password} />
                      <Route exact path="/error_status" component={Error_status} />
                      <Route exact path="/"  render={() => <Redirect to="/contractor" />} />
                    </Switch>
                  </body>
              </tr>
            </table>
          </div>
        </BrowserRouter>
      </AmplifyAuthenticator>
    );
}
const mapStateToProps = (state) => {
  return { count: state.count };
};
export default connect(mapStateToProps)(App);