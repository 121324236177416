import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useHistory  } from 'react-router-dom';
import '../App.css';
import testJson from '.././test/testJson/device_list_test.json';
import { Storage } from 'aws-amplify';
import Amplify, {Auth} from 'aws-amplify';
import API_GATEWAY from "./hostname";

const getIdToken = () =>{
    return new Promise((resolve, reject) => {
        try{
            Auth.currentSession().then((data) => {
                return resolve(data.getIdToken().getJwtToken());
              });
        }
        catch(e){
            console.log('Not signed in');
            return reject('ID取得失敗')
        }  
    })
  
}
function Device_list() {
    const [keyLinks, setKeyLinks] = useState();
    const [gwLinks, setGwLinks] = useState();
    const [authType, setAuthType] = useState('');
    const [redisp, setRedisp] = useState(false);
    const [hotelName, setHotelName] = useState('');

    useEffect(() => {
        isAdmin();
        if(location.state){
            setHotelName(location.state.hotelName)
        }
        else{
            history.push({
                pathname: "/",
            });
        }
    },[])
    useEffect(() => {
        if (authType != ''){
            // 機器一覧のリンクを取得
            getKeyLinks()
                    .then(result => {
                        setKeyLinks(result.keyList)
                        setGwLinks(result.gwList)
                    }).catch(error => {
                        console.error("機器一覧リンク作成失敗")
                    })
        }
    },[authType, redisp])

    const getKeyLinks = () =>{
        return new Promise((resolve, reject) => {
            try{
                getIdToken()
                .then(result => {
                    const method = "GET";
                    const headers = {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization':result
                      };
                    var url = API_GATEWAY + "/console/v1/devices/"+ location.state.hotelID;

                    fetch(url,
                    {method:method, headers:headers})
                    .then((res)=> res.json())
                      .then(data => {
                        console.log("鍵一覧取得のfetch実行完了")
                        var keyList = [];
                        for(var i in data.locks){
                            keyList.push(
                            <tr>
                                <td>
                                    <label class="li_left"><button class="link_style_button" onClick={lockHandleClick.bind(this, data.locks[i])}>{data.locks[i].serial_number}</button></label>
                                </td>
                                <td>
                                    <label class="li_left">{data.locks[i].floor}</label>
                                </td>
                                <td>
                                    <label class="li_left">{data.locks[i].name}</label>
                                </td>
                                {authType == 'admin' &&
                                <td>
                                    <button class="button" type="submit" id={data.locks[i].lock_id} value="削除" onClick={handleDeleteLockClick.bind(this)}>削除</button>
                                </td>}
                            </tr>
                            );
                        }
                        var gwList = [];
                        for(var i in data.gws){
                            gwList.push(
                            <tr>
                                <td>
                                    <label class="li_left"><button class="link_style_button" onClick={gatewayHandleClick.bind(this, data.gws[i])}>{data.gws[i].serial_number}</button></label>
                                </td>
                                <td>
                                    <label class="li_left">{data.gws[i].floor}</label>
                                </td>
                                <td>
                                    <label class="li_left">-</label>
                                </td>
                                {authType == 'admin' &&
                                <td>
                                    <button class="button" type="submit" id={data.gws[i].gw_id} value="削除" onClick={handleDeleteGwClick.bind(this)}>削除</button>
                                </td>}
                            </tr>
                                );
                                gwList.push(<br></br>);
                        }
                        const list = {"keyList":keyList, "gwList":gwList};
                        return resolve(list);
                      },[])
                      .catch(err => {
                        console.log("鍵一覧取得のfetch失敗")
                        alert("鍵一覧情報の取得に失敗しました")
                        return reject('鍵一覧取得失敗')
                        })
                }).catch(error => {
                    console.error("IDトークン取得失敗")
                    return reject('IDトークン取得失敗')
                })
            }
            catch(e){
                console.log('ホテル一覧取得失敗');
                return reject('ホテル一覧取得失敗')
            }  
        })
      
    }

    const history = useHistory();
    const location = useLocation();

    async function isAdmin(){
        try{
            const user = await Auth.currentUserPoolUser();
            const attrs = await Auth.userAttributes(user);
            attrs.forEach(function(attr){
                if (attr.Name == 'custom:authority'){
                    setAuthType(attr.Value);
                }
            });
        }
        catch(e){
            Auth.signOut();
        }
    }

    async function handleDeleteLockClick(e: React.MouseEvent){
        e.preventDefault(); // フォーム送信を抑止
        const lock_id = e.target.id;
        if (!window.confirm(lock_id + "を削除しますか？")){
            return;
        }
        getIdToken()
        .then(result => {
            const method = "DELETE";
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':result
            };
            const deleteLockUrl = API_GATEWAY + "/console/v1/devices/locks/"+lock_id;
            fetch(deleteLockUrl,
            {method:method, headers:headers})
            .then(res => {
                if (res.ok){
                    console.log("fetch実行完了")
                    // リストを再設定
                    setRedisp(!redisp);
                    return true;
                }else{
                    alert("削除中にエラーが起きました")
                }})
                .catch(err => {
                    console.log("fetch失敗")
                    alert("削除中にエラーが起きました")
                })
        }).catch(error => {
            console.error("IDトークン取得失敗")
        });
        return false;
    }
    async function handleDeleteGwClick(e: React.MouseEvent){
        e.preventDefault(); // フォーム送信を抑止
        const gw_id = e.target.id;
        if (!window.confirm(gw_id + "を削除しますか？")){
            return;
        }
        getIdToken()
        .then(result => {
            const method = "DELETE";
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':result
            };
            const deleteGwUrl = API_GATEWAY + "/console/v1/devices/gws/"+gw_id;
            fetch(deleteGwUrl,
            {method:method, headers:headers})
            .then(res => {
                if (res.ok){
                    console.log("fetch実行完了")
                    // リストを再設定
                    setRedisp(!redisp);
                    return true;
                }else{
                    console.log(res.status)
                    alert("削除中にエラーが起きました")
                }})
                .catch(err => {
                    console.log("fetch失敗")
                    alert("削除中にエラーが起きました")
                })
        }).catch(error => {
            console.error("IDトークン取得失敗")
        });
        return false;
    }

    const uploadHandleChange = (event) => {
        const filename = event.target.value;
        if (filename == ''){
            console.log("No file");
            return;
        }
        if (filename.slice(-4) != '.csv'){
            alert("csvファイルを指定してください");
            return;
        }

        s3upload(filename, event.target.files[0], event.target.name);
    }

    function s3upload(name, file, type) {
        const filePath = location.state.hotelID + "/" + type + "/" + name.substr(name.lastIndexOf("\\") + 1);
        console.log(filePath);
        Storage.put(filePath, file, {
            level: 'private'
        }).then(result => {
            alert("アップロード完了");
        }).catch(err => {
            alert("アップロードに失敗しました");
        });
    }

    function lockHandleClick(locksData){
        history.push({
          pathname: "/setting_doorlock",
          state: { lockName: locksData.name, lockID: locksData.lock_id }
      });
    }

    function gatewayHandleClick(gatewayData){
        history.push({
          pathname: "/setting_gateway",
          state: {
            gwSerialNumber: gatewayData.serial_number,
            gwID: gatewayData.gw_id,
            hotelID: location.state.hotelID
          }
      });
    }

    return (
        <div class="">
            <table class="center">
                <tr>
                    <td colspan="4">
                    {hotelName}　機器一覧
                    </td>
                </tr>
                <label class="">ドアロック</label>
                {authType == 'admin' &&
                <label class="file_upload_button_label">登録<input class="file_upload_button" type='file' accept=".csv" name="ddl" onChange={uploadHandleChange} /></label>}
                &nbsp;
                <tr>
                    <th>
                        <label class="li_left">Serial Number</label>
                    </th>
                    <th>
                        <label class="li_left">Floor</label>
                    </th>
                    <th>
                        <label class="li_left">roomNumber</label>
                    </th>
                </tr>
                {/* ここから電子錠一覧のリンクのリストが表示される */}
                {keyLinks}
                {/* ここまで電子錠一覧のリンクのリストが表示される */}
            </table>

            <table class="center">
                <label class="li_left">ゲートウェイ</label>
                {authType == 'admin' &&
                <label class="file_upload_button_label">登録<input class="file_upload_button" type='file' accept=".csv" name="gw" onChange={uploadHandleChange} /></label>}
                &nbsp;
                <tr>
                    <th>
                        <label class="li_left">Serial Number</label>
                    </th>
                    <th>
                        <label class="li_left">Floor</label>
                    </th>
                    <th>
                        <label class="li_left">roomNumber</label>
                    </th>
                </tr>
                {/* ここからゲートウェイ一覧のリンクのリストが表示される */}
                {gwLinks}
                {/* ここまでゲートウェイ一覧のリンクのリストが表示される */}
            </table>
            {/* TODO：共用部コメントアウト */}
            {/* <br></br>
            <br></br> */}
                {/* <ul>
                    <li>
                        <label class="li_left">共用部</label>
                        {authType == 'admin' &&
                        <label class="file_upload_button_label">登録<input class="file_upload_button" type='file' accept=".csv" name="common" /></label>}
                        &nbsp;
                    </li>
                    <br></br>
                    <li>
                        <label class="li_left">Topic</label>
                    </li>
                    <br></br>
                    <li>
                        <label class="li_left"><Link class="li_left" to={`/`}>/lead001/</Link></label>
                    </li>
                </ul> */}
        </div>
    );
}

export default Device_list;
