import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useHistory  } from 'react-router-dom';
import '../App.css';
import testJson from '../test/testJson/device_list_test.json';
import { Storage } from 'aws-amplify';
import Amplify, {Auth} from 'aws-amplify';
import AWS from 'aws-sdk'
import API_GATEWAY from "./hostname";
import Pagination from './pagination/Pagination.js';
import loadimg from "../images/loading.gif";
import S3_FW_BUCKET_NAME from "./s3bucketname";

AWS.config.update({
    accessKeyId: 'AKIAR7ANV7GJZZ2WZVAA',
    secretAccessKey: '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'
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_FW_BUCKET_NAME},
    region: 'ap-northeast-1'
})

const getIdToken = () =>{
    return new Promise((resolve, reject) => {
        try{
            Auth.currentSession().then((data) => {
                return resolve(data.getIdToken().getJwtToken());
              });
        }
        catch(e){
            console.log('Not signed in');
            return reject('ID取得失敗')
        }  
    })
  
}
function Gw_list() {
    const [gwLinks, setGwLinks] = useState([]);
    const [authType, setAuthType] = useState('');
    const [redisp, setRedisp] = useState(false);
    const [hotelName, setHotelName] = useState('');
    const numOfLine = 10;
    const [loading, setLoading] = useState(true);
    const [fw_bin, setFw_bin] = useState();
    const [fw_md5, setFw_md5] = useState();

    useEffect(() => {
        isAdmin();
        if(location.state){
            setHotelName(location.state.hotelName)
        }
        else{
            history.push({
                pathname: "/",
            });
        }
    },[])
    useEffect(() => {
        if (authType != ''){
            // 機器一覧のリンクを取得
            getKeyLinks()
                    .then(result => {
                        setGwLinks(result.gwList)
                        setLoading(false)
                    }).catch(error => {
                        console.error("機器一覧リンク作成失敗")
                        setLoading(false)
                    })
            if(authType == 'admin'){
                getS3_fw().then(result => {
                    setFw_bin(result["bin"])
                    setFw_md5(result["md5"])
                })
            }
            
        }
    },[authType, redisp])

    const getKeyLinks = () =>{
        return new Promise((resolve, reject) => {
            try{
                getIdToken()
                .then(result => {
                    const method = "GET";
                    const headers = {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization':result
                      };
                    var url = API_GATEWAY + "/console/v1/devices/"+ location.state.hotelID;

                    fetch(url,
                    {method:method, headers:headers})
                    .then((res)=> res.json())
                      .then(data => {
                        console.log("ゲートウェイ一覧取得のfetch実行完了")
                        data.gws.sort(function(a,b){
                            var num1 = 99999;
                            var num2 = 10000;
                            if(a.floor){
                                var num1 = Number(a.floor.replace(/[^0-9]/g, ''));
                            }
                            if(b.floor){
                                var num2 = Number(b.floor.replace(/[^0-9]/g, ''));
                            }
                            if(num1 < num2) return -1;
                            if(num1 > num2) return 1;
                            return 0;
                        });
                        var gwList = [];
                        for(var i in data.gws){
                            gwList.push(
                            <tr>
                                <td class="table_border_updown_thtd_left_number">
                                    {Number(i)+1}
                                </td>
                                <td class="table_border_updown_thtd_left">
                                    <label class=""><button class="link_style_button" onClick={gatewayHandleClick.bind(this, data.gws[i])}>{data.gws[i].serial_number}</button></label>
                                </td>
                                <td class="table_border_updown_thtd_right">
                                    <label class="">　{data.gws[i].floor}</label>
                                </td>
                                <td class="table_border_updown_thtd_right">
                                    <label class="">　{data.gws[i].name}</label>
                                </td>
                                <td class="table_border_updown_thtd_right">
                                    {authType == 'admin' &&
                                    <button class="button" type="submit" id={data.gws[i].gw_id} value="削除" onClick={handleDeleteGwClick.bind(this, data.gws[i].gw_id, data.gws[i].serial_number)}>削除</button>}
                                </td>
                            </tr>
                                );
                        }
                        // ページネーション用。リストの数が１ページ表示行数より多い時の処理。
                        if(gwList){
                            if(numOfLine < gwList.length){
                                const amari = gwList.length % numOfLine;
                                const blank_tr_count = numOfLine - amari;
                                if(amari != 0){
                                    for(var l=0;l<blank_tr_count;l++){
                                        gwList.push(<tr></tr>)
                                    }   
                                }
                            }
                        }
                        const list = {"gwList":gwList};
                        return resolve(list);
                      },[])
                      .catch(err => {
                        console.log("ゲートウェイ一覧取得のfetch失敗")
                        alert("ゲートウェイ一覧情報の取得に失敗しました")
                        return reject('ゲートウェイ一覧取得失敗')
                        })
                }).catch(error => {
                    console.error("IDトークン取得失敗")
                    return reject('IDトークン取得失敗')
                })
            }
            catch(e){
                console.log('ホテル一覧取得失敗');
                return reject('ホテル一覧取得失敗')
            }  
        })
      
    }

    const history = useHistory();
    const location = useLocation();

    async function isAdmin(){
        try{
            const user = await Auth.currentUserPoolUser();
            const attrs = await Auth.userAttributes(user);
            attrs.forEach(function(attr){
                if (attr.Name == 'custom:authority'){
                    setAuthType(attr.Value);
                }
            });
        }
        catch(e){
            Auth.signOut();
        }
    }

    async function handleDeleteGwClick(gw_id, serial_number){
        if (!window.confirm(serial_number + "を削除しますか？")){
            return;
        }
        getIdToken()
        .then(result => {
            const method = "DELETE";
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':result
            };
            const deleteGwUrl = API_GATEWAY + "/console/v1/devices/gws/"+gw_id;
            fetch(deleteGwUrl,
            {method:method, headers:headers})
            .then(res => {
                if (res.ok){
                    console.log("fetch実行完了")
                    // リストを再設定
                    setRedisp(!redisp);
                    return true;
                }else{
                    console.log(res.status)
                    alert("削除中にエラーが起きました")
                }})
                .catch(err => {
                    console.log("fetch失敗")
                    alert("削除中にエラーが起きました")
                })
        }).catch(error => {
            console.error("IDトークン取得失敗")
        });
        return false;
    }

    const uploadHandleChange = (event) => {
        const filename = event.target.value;
        if (filename == ''){
            console.log("No file");
            return;
        }
        if (filename.slice(-4) != '.csv'){
            alert("csvファイルを指定してください");
            return;
        }
        s3upload(filename, event.target.files[0], event.target.name);
    }

    function s3upload(name, file, type) {
        const filePath = location.state.hotelID + "/" + type + "/" + name.substr(name.lastIndexOf("\\") + 1);
        Storage.put(filePath, file, {
            level: 'private'
        }).then(result => {
            alert("アップロード完了");
        }).catch(err => {
            alert("アップロードに失敗しました");
        });
    }

    const fwUploadHandleChange = (event) => {
        const filename = event.target.value;
        if (filename == ''){
            console.log("No file");
            return;
        }
        if (filename.slice(-4) != '.bin' && filename.slice(-4) != '.md5'){
            alert("binファイルまたはmd5ファイルを指定してください");
            return;
        }
        s3delete_fw(filename.slice(-4)).then(result => {
            if(result == "success"){
                s3upload_fw(event.target.files[0]);
            }
        })
    }

    function s3upload_fw(file) {
        myBucket.putObject({
            Body: file,
            Bucket: S3_FW_BUCKET_NAME,
            Key: location.state.hotelID + "/" + file.name
        }).promise().then(result => {
            alert("アップロード完了");
            window.location.reload();
        }).catch(err => {
            alert("アップロードに失敗しました");
        });
    }
    const s3delete_fw = (ext) =>{
        return new Promise((resolve, reject) => {
            try{
                getS3_fw().then(result => {
                    if(ext == ".bin"){
                        if(result["bin"]){
                            myBucket.deleteObject({
                                Bucket: S3_FW_BUCKET_NAME,
                                Key: location.state.hotelID + "/"+ result["bin"]
                            }).promise().then(result => {
                                console.log("binファイル削除完了");
                                return resolve("success")
                            }).catch(err => {
                                console.log("binファイルの削除に失敗しました");
                            });
                        }
                        else{
                            return resolve("success")
                        }
                    }
                    if(ext == ".md5"){
                        if(result["md5"]){
                            myBucket.deleteObject({
                                Bucket: S3_FW_BUCKET_NAME,
                                Key: location.state.hotelID + "/"+ result["md5"]
                            }).promise().then(result => {
                                console.log("md5ファイル削除完了");
                                return resolve("success")
                            }).catch(err => {
                                console.log("md5ファイルの削除に失敗しました");
                            });
                        }
                        else{
                            return resolve("success")
                        }
                    }
                })
            }
            catch(e){
                console.log('fwファイル削除失敗');
                return reject('fwファイル削除失敗')
            }
        })
        
    }

    const getS3_fw = () =>{
        return new Promise((resolve, reject) => {
            try{
                myBucket.listObjectsV2({
                    Bucket: S3_FW_BUCKET_NAME,
                    Prefix: location.state.hotelID
                }).promise().then(result => {
                    // ファイルリスト取得成功
                    var fw_file = {"bin":"", "md5":""};
                    if(result && result.Contents){
                        for(var i = 0; i < result.Contents.length; i++){
                            const name = result.Contents[i].Key;
                            if(result.Contents[i].Key.slice(-4) == ".bin"){
                                fw_file["bin"] = name.substr(name.lastIndexOf("/") + 1);
                            }
                            else if(result.Contents[i].Key.slice(-4) == ".md5"){
                                fw_file["md5"] = name.substr(name.lastIndexOf("/") + 1);
                            }
                        }
                    }
                    return resolve(fw_file);
                }).catch(err => {
                    // ファイルリスト取得失敗
                    console.log("ファイルリストの取得に失敗しました");
                });
            }
            catch(e){
                console.log('fwファイル取得失敗');
                return reject('fwファイル取得失敗')
            }
        })
    }

    function gatewayHandleClick(gatewayData){
        history.push({
          pathname: "/setting_gateway",
          state: {
            gwSerialNumber: gatewayData.serial_number,
            gwID: gatewayData.gw_id,
            hotelID: location.state.hotelID,
            hotelName: location.state.hotelName,
            contractorID: location.state.contractorID
          }
      });
    }

    if (loading){
        return (

        <div class="center_parents">
            <table class="overall_table">
                <tr>
                    <td>
                        <div class="title_text">ゲートウェイ一覧　＜{hotelName}＞</div>
                    </td>
                    <td>
                        {authType == 'admin' &&
                        <label class="regist_button_right_1">登録<input class="file_upload_button" type='file' accept=".csv" name="gw" onChange={uploadHandleChange} /></label>}
                </td>
                </tr>
            </table>

            <hr color="#00B4C6" size="6" />
            <br />
            <table class="table_border overall_table">
                <tr>
                    <td>
                        <div>
                        <img src={loadimg}/>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        );
    }
    return (
        <div class="">
            <table class="overall_table">
                <tr>
                    <td>
                        <div class="title_text">ゲートウェイ一覧　＜{hotelName}＞</div>
                    </td>
                    <td>
                        {authType == 'admin' &&
                        <label class="regist_button_right_1">登録<input class="file_upload_button" type='file' accept=".csv" name="gw" onChange={uploadHandleChange} /></label>}
                </td>
                </tr>
            </table>

            <hr color="#00B4C6" size="6" />
            <br />
            <table class="table_border overall_table">
                <tr>
                    <td class="table_border_updown_thtd_left_titlenumber">
                        No.
                    </td>
                    <td class="table_short_title">
                        　Serial Number
                    </td>
                    <td class="table_short_title">
                        　フロア
                    </td>
                    <td class="table_short_title">
                        　備考
                    </td>
                </tr>
                <Pagination
                dataList={gwLinks}
                col_count={5}
                numOfLine={numOfLine}
                />
                
            </table>
            {authType == 'admin' &&
            <table class="table_border overall_table">
                <tr>
                <th style={{width:"120px"}}></th>
                <th style={{width:"200px"}}></th>
                </tr>
                <tr>
                    <td class="table_border_updown_thtd_left" rowSpan={2}>FW情報</td>
                    <td class="table_border_white_down_thtd_left center">
                        binファイル
                    </td>
                    <td class="table_border_up_thtd_right">
                        <label class="">{fw_bin}</label>
                    </td>
                    <td class="table_border_updown_thtd_right" rowSpan={2}>
                        {authType == 'admin' &&
                        <label class="button">登録<input class="file_upload_button" type='file' accept=".bin,.md5" name="fw" onChange={fwUploadHandleChange} /></label>}
                    </td>
                </tr>
                <tr>
                    <td class="table_border_white_up_thtd_left center">
                        md5ファイル
                    </td>
                    <td class="table_border_down_thtd_right">
                        <label class="">{fw_md5}</label>
                    </td>
                </tr>
            </table>}

        </div>
    );
}

export default Gw_list;
