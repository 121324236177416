import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useHistory  } from 'react-router-dom';
import '../App.css';
import testJson from '../test/testJson/device_list_test.json';
import { Storage } from 'aws-amplify';
import Amplify, {Auth} from 'aws-amplify';
import API_GATEWAY from "./hostname";
import Pagination from './pagination/Pagination.js';
import loadimg from "../images/loading.gif";

const getIdToken = () =>{
    return new Promise((resolve, reject) => {
        try{
            Auth.currentSession().then((data) => {
                return resolve(data.getIdToken().getJwtToken());
              });
        }
        catch(e){
            console.log('Not signed in');
            return reject('ID取得失敗')
        }  
    })
  
}
function Doorlock_list() {
    const [keyLinks, setKeyLinks] = useState([]);
    const [authType, setAuthType] = useState('');
    const [redisp, setRedisp] = useState(false);
    const [hotelName, setHotelName] = useState('');
    const numOfLine = 10;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        isAdmin();
        if(location.state){
            setHotelName(location.state.hotelName)
        }
        else{
            history.push({
                pathname: "/",
            });
        }
    },[])
    useEffect(() => {
        if (authType != ''){
            // 機器一覧のリンクを取得
            getKeyLinks()
                    .then(result => {
                        setKeyLinks(result.keyList)
                        setLoading(false)
                    }).catch(error => {
                        console.error("機器一覧リンク作成失敗")
                        setLoading(false)
                    })
        }
    },[authType, redisp])

    const getKeyLinks = () =>{
        return new Promise((resolve, reject) => {
            try{
                getIdToken()
                .then(result => {
                    const method = "GET";
                    const headers = {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization':result
                      };
                    var url = API_GATEWAY + "/console/v1/devices/"+ location.state.hotelID;

                    fetch(url,
                    {method:method, headers:headers})
                    .then((res)=> res.json())
                      .then(data => {
                        console.log("鍵一覧取得のfetch実行完了")
                        // 数字を見てソート
                        data.locks.sort(function(a,b){
                            var num1 = 99999;
                            var num2 = 10000;
                            if(a.name){
                                var num1 = Number(a.name.replace(/[^0-9]/g, ''));
                            }
                            if(b.name){
                                var num2 = Number(b.name.replace(/[^0-9]/g, ''));
                            }
                            if(num1 < num2) return -1;
                            if(num1 > num2) return 1;
                            return 0;
                        });
                        var keyList = [];
                        for(var i in data.locks){
                            keyList.push(
                            <tr>
                                <td class="table_border_updown_thtd_left_number">
                                    {Number(i)+1}
                                </td>
                                <td class="table_border_updown_thtd_left">
                                    <label class=""><button class="link_style_button" onClick={lockHandleClick.bind(this, data.locks[i], data.access_groups)}>{data.locks[i].serial_number}</button></label>
                                </td>
                                <td class="table_border_updown_thtd_right">
                                    <label class="">　{data.locks[i].floor}</label>
                                </td>
                                <td class="table_border_updown_thtd_right">
                                    <label class="">　{data.locks[i].name}</label>
                                </td>
                                <td class="table_border_updown_thtd_right">
                                    {authType == 'admin' &&
                                    <button class="button" type="submit" id={data.locks[i].lock_id} value="削除" onClick={handleDeleteLockClick.bind(this, data.locks[i].lock_id, data.locks[i].serial_number)}>削除</button>}
                                </td>
                            </tr>
                            );
                        }
                        // ページネーション用。リストの数が１ページ表示行数より多い時の処理。
                        if(keyList){
                            if(numOfLine < keyList.length){
                                const amari = keyList.length % numOfLine;
                                const blank_tr_count = numOfLine - amari;
                                if(amari != 0){
                                    for(var l=0;l<blank_tr_count;l++){
                                        keyList.push(<tr></tr>)
                                    }   
                                }
                            }
                        }
                        const list = {"keyList":keyList};
                        return resolve(list);
                      },[])
                      .catch(err => {
                        console.log("鍵一覧取得のfetch失敗")
                        alert("鍵一覧情報の取得に失敗しました")
                        return reject('鍵一覧取得失敗')
                        })
                }).catch(error => {
                    console.error("IDトークン取得失敗")
                    return reject('IDトークン取得失敗')
                })
            }
            catch(e){
                console.log('鍵一覧取得失敗');
                return reject('鍵一覧取得失敗')
            }  
        })
      
    }

    const history = useHistory();
    const location = useLocation();

    async function isAdmin(){
        try{
            const user = await Auth.currentUserPoolUser();
            const attrs = await Auth.userAttributes(user);
            attrs.forEach(function(attr){
                if (attr.Name == 'custom:authority'){
                    setAuthType(attr.Value);
                }
            });
        }
        catch(e){
            Auth.signOut();
        }
    }

    async function handleDeleteLockClick(lock_id, serial_number){
        if (!window.confirm(serial_number + "を削除しますか？")){
            return;
        }
        getIdToken()
        .then(result => {
            const method = "DELETE";
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':result
            };
            const deleteLockUrl = API_GATEWAY + "/console/v1/devices/locks/"+lock_id;
            fetch(deleteLockUrl,
            {method:method, headers:headers})
            .then(res => {
                if (res.ok){
                    console.log("fetch実行完了")
                    // リストを再設定
                    setRedisp(!redisp);
                    return true;
                }else{
                    console.log("エラー")
                    alert("削除中にエラーが起きました")
                }})
                .catch(err => {
                    console.log("fetch失敗")
                    alert("削除中にエラーが起きました")
                })
        }).catch(error => {
            console.error("IDトークン取得失敗")
        });
        return false;
    }
   

    const uploadHandleChange = (event) => {
        const filename = event.target.value;
        if (filename == ''){
            console.log("No file");
            return;
        }
        if (filename.slice(-4) != '.csv'){
            alert("csvファイルを指定してください");
            return;
        }
        s3upload(filename, event.target.files[0], event.target.name);
    }

    function s3upload(name, file, type) {
        const filePath = location.state.hotelID + "/" + type + "/" + name.substr(name.lastIndexOf("\\") + 1);
        Storage.put(filePath, file, {
            level: 'private'
        }).then(result => {
            console.log(filePath + " アップロード完了");
            alert("アップロード完了");
        }).catch(err => {
            alert("アップロードに失敗しました");
        });
    }

    function lockHandleClick(locksData, access_groups){
        history.push({
          pathname: "/setting_doorlock",
          state: { lockName: locksData.name, lockID: locksData.lock_id, access_groups: access_groups, hotelName: location.state.hotelName, hotelID: location.state.hotelID, contractorID: location.state.contractorID }
      });
    }

    if (loading){
        return (

        <div class="center_parents">
            <table class="overall_table">
                <tr>
                    <td>
                        <div class="title_text">ドアロック一覧　＜{hotelName}＞</div>
                    </td>
                    <td>
                        {authType == 'admin' &&
                        <label class="regist_button_right_1">新規登録<input class="file_upload_button" type='file' accept=".csv" name="ddl" onChange={uploadHandleChange} /></label>}
                    </td>
                </tr>
            </table>

            <hr color="#00B4C6" size="6" />
            <br />
            <table class="table_border overall_table">
                <tr>
                    <td>
                        <div>
                        <img src={loadimg}/>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        );
    }
    return (
        <div class="">
            <table class="overall_table">
                <tr>
                    <td>
                        <div class="title_text">ドアロック一覧　＜{hotelName}＞</div>
                    </td>
                    <td>
                        {authType == 'admin' &&
                        <label class="regist_button_right_1">新規登録<input class="file_upload_button" type='file' accept=".csv" name="ddl" onChange={uploadHandleChange} /></label>}
                    </td>
                </tr>
            </table>

            <hr color="#00B4C6" size="6" />
            <br />
            <table class="table_border overall_table">
                <tr>
                    <td class="table_border_updown_thtd_left_titlenumber">
                        No.
                    </td>
                    <td class="table_short_title">
                        　Serial Number
                    </td>
                    <td class="table_short_title">
                        　フロア
                    </td>
                    <td class="table_short_title">
                        　部屋番号
                    </td>
                </tr>
                <Pagination
                dataList={keyLinks}
                col_count={5}
                numOfLine={numOfLine}
                />
            </table>
        </div>
    );
}

export default Doorlock_list;
