import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import '../App.css';
import testJson from '.././test/testJson/contractorTest.json';
import Amplify, {Auth} from 'aws-amplify';
import API_GATEWAY from "./hostname";
import {AuthState, onAuthUIStateChange} from "@aws-amplify/ui-components";
import Pagination from './pagination/Pagination.js';
import loadimg from "../images/loading.gif";

const getIdToken = () =>{
    return new Promise((resolve, reject) => {
        try{
            Auth.currentSession().then((data) => {
                return resolve(data.getIdToken().getJwtToken());
              });
        }
        catch(e){
            console.log('Not signed in');
            return reject('ID取得失敗')
        }  
    })
  
}
function Contractor(){
    const [links, setLinks] = useState([]);
    const [authType, setAuthType] = useState('');
    const [contractorList, setContractorList] = useState([]);
    const location = useLocation();
    const [redisp, setRedisp] = useState(false);
    const numOfLine = 10;
    const [loading, setLoading] = useState(true);

    function handleSubmit(e) {
        e.preventDefault();
        console.log('You clicked submit.');
        // alert(state.roomNumber);
    }

    useEffect(() => {
        isAdmin();
        // サインイン完了後のコールバック
        onAuthUIStateChange((nextAuthState, authData) => {
            if (nextAuthState == AuthState.SignedIn){
                if (authType == ''){
                    isAdmin();
                }
            }
        });
    },[])
    useEffect(() => {
        if (authType != ''){
            // 契約者一覧のリンクを取得
            getContractorsLinks()
                    .then(result => {
                        setLinks(result)
                        setLoading(false)
                    }).catch(error => {
                        console.error("契約者一覧リンク作成失敗:"+error)
                        setLoading(false)
                    })
        }
    },[authType, redisp])


    var handleChange = (event) => {
        let buff = event.target.value;
        // setState({roomNumber: buff});
    }


    const getContractorsLinks = () =>{
        return new Promise((resolve, reject) => {
            try{
                getIdToken()
                .then(result => {
                    const method = "GET";
                    const headers = {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization':result
                      };
                    fetch(API_GATEWAY + '/console/v1/contractors',
                    {method:method, headers:headers})
                    .then((res)=> res.json())
                      .then(data => {
                        console.log("契約企業一覧取得のfetch実行完了")

                        // ソート（文字辞書順）
                        data.contractors.sort(function(a,b){
                            if(a.name < b.name) return -1;
                            if(a.name > b.name) return 1;
                            return 0;
                        });

                        var list = [];
                        for(var i in data.contractors){
                            if (authType != 'admin' && !contractorList.includes(data.contractors[i].contractor_id)){
                                continue;
                            }
                            list.push(
                                <tr>
                                    <td class="table_border_updown_thtd_left_number">
                                        {Number(i)+1}
                                    </td>
                                    <td class="table_border_updown_thtd_left">
                                        <button class="link_style_button" onClick={handleClick.bind(this, data.contractors[i])}>{data.contractors[i].name}</button>
                                    </td>
                                    
                                    <td class="table_border_updown_thtd_right">
                                        {authType == 'admin' &&
                                        <button class="button" onClick={editHandleClick.bind(this, data.contractors[i])}>編集</button>}
                                        {authType == 'admin' &&
                                        <button class="button" id={data.contractors[i].contractor_id} onClick={deleteHandleClick.bind(this, data.contractors[i].contractor_id, data.contractors[i].name)}>削除</button>}

                                    </td>
                                </tr>
                            );
                        }
                        // ページネーション用。リストの数が１ページ表示行数より多い時の処理。
                        if(list){
                            if(numOfLine < list.length){
                                const amari = list.length % numOfLine;
                                const blank_tr_count = numOfLine - amari;
                                if(amari != 0){
                                    for(var l=0;l<blank_tr_count;l++){
                                        list.push(<tr></tr>)
                                    }   
                                }
                            }
                        }

                        return resolve(list);
                      },[])
                      .catch(err => {
                        console.log("契約企業一覧取得のfetch失敗")
                        alert("契約企業一覧の取得に失敗しました")
                        return reject('契約企業一覧取得失敗')
                        })
                }).catch(error => {
                    console.error("IDトークン取得失敗")
                    return reject('IDトークン取得失敗')
                })
            }
            catch(e){
                console.log('契約者一覧取得失敗');
                return reject('契約者一覧取得失敗')
            }  
        })
      
    }

    const history = useHistory();

    function handleClick(contractorData){
        history.push({
          pathname: "/hotellist",
          state: {
              contractorName: contractorData.name,
              contractorID: contractorData.contractor_id
            }
      });
    }
    function editHandleClick(contractorData){
        history.push({
          pathname: "/edit_contractor",
          state: {
            contractorName: contractorData.name,
            contractorID: contractorData.contractor_id
          }      });
    }
    function deleteHandleClick(contractor_id, name){
        if (!window.confirm(name + "を削除しますか？")){
            return;
        }
        getIdToken()
        .then(result => {
            const method = "DELETE";
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':result
            };
            const deleteLockUrl = API_GATEWAY + "/console/v1/contractors/"+contractor_id;
            fetch(deleteLockUrl,
            {method:method, headers:headers})
            .then(res => {
                if (res.ok){
                    console.log("fetch実行完了")
                    // リストを再設定
                    setRedisp(!redisp);
                    return true;
                }else{
                    console.log("エラー："+res.status)
                    alert("削除中にエラーが起きました")
                }})
                .catch(err => {
                    console.log("fetch失敗")
                    alert("削除中にエラーが起きました")
                })
        }).catch(error => {
            console.error("IDトークン取得失敗")
        });
        return false;
    }
    async function isAdmin(){
        try{
            const user = await Auth.currentUserPoolUser();
            const attrs = await Auth.userAttributes(user);
            // 先にhotelListをsetして次のstateでauthをsetする
            attrs.forEach(function(attr){
                if (attr.Name == 'custom:contractors'){
                    const contractors = attr.Value.split(/\s/);
                    setContractorList(contractors);
                }
            });
            attrs.forEach(function(attr){
                if (attr.Name == 'custom:authority'){
                    setAuthType(attr.Value);
                }
            });
        }
        catch(e){
            /*
            現状、トークン無効エラーでのみここに来るので無条件でサインアウトする。
            今後条件判定が必要であれば、e.code=="NotAuthorizedException"などで見ればよい。
            {code: 'NotAuthorizedException', name: 'NotAuthorizedException', message: 'Access Token has been revoked'}
            */
            Auth.signOut();
        }
    }

    if (loading){
        return (

        <div class="center_parents">
            <table class="overall_table">
                <tr>
                    <td>
                        <div class="title_text">契約者一覧</div>
                    </td>
                </tr>
            </table>

            <hr color="#00B4C6" size="6" />
            <br />
            <table class="table_border overall_table">
                <tr>
                    <td>
                        <div>
                        <img src={loadimg}/>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        );
    }
    return (
        
        <div class="center_parents">
            <table class="overall_table">
                <tr>
                    <td>
                        <div class="title_text">契約者一覧</div>
                    </td>
                    <td>
                    {authType == 'admin' &&
                        <Link　class="regist_button_right_1" to={`/regist_contractor`}>新規登録</Link>}
                    </td>
                </tr>
            </table>

            <hr color="#00B4C6" size="6" />
            <br />
            <table class="table_border overall_table">
                <tr>
                    <td class="table_border_updown_thtd_left_titlenumber">
                        No.
                    </td>
                    <td class="table_border_updown_thtd_left_title">
                        契約会社
                    </td>
                </tr>
                <Pagination
                dataList={links}
                col_count={3}
                numOfLine={numOfLine}
                />
            </table>

        </div>
    );
}
export default Contractor;