import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import '../App.css';
import testJson from '.././test/testJson/hotellistTest.json';
import Amplify, {Auth} from 'aws-amplify';
import API_GATEWAY from "./hostname";
import Pagination from './pagination/Pagination.js';
import loadimg from "../images/loading.gif";
import getCognitoProvider from "./cognite_common";

const getIdToken = () =>{
    return new Promise((resolve, reject) => {
        try{
            Auth.currentSession().then((data) => {
                return resolve(data.getIdToken().getJwtToken());
              });
        }
        catch(e){
            console.log('Not signed in');
            return reject('ID取得失敗')
        }  
    })
  
}

function Hotellist() {
    const [links, setLinks] = useState([]);
    const [authType, setAuthType] = useState('');
    const [hotelList, setHotelList] = useState([]);
    const location = useLocation();
    const [contractorName, setContractorName] = useState('');
    const [redisp, setRedisp] = useState(false);
    const numOfLine = 10;
    const [loading, setLoading] = useState(true);

    function handleSubmit(e) {
        e.preventDefault();
        console.log('You clicked submit.');
        // alert(state.roomNumber);
    }
    
    useEffect(() => {
        isAdmin();
        if(location.state){
            setContractorName(location.state.contractorName)
        }
        else{
            history.push({
                pathname: "/",
            });
        }
    },[])
    useEffect(() => {
        if (authType != ''){
            // ホテル一覧のリンクを取得
            getHotelLinks()
                    .then(result => {
                        setLinks(result)
                        setLoading(false)
                    }).catch(error => {
                        console.error("ホテル一覧リンク作成失敗")
                        setLoading(false)
                    })
        }
    },[authType, redisp])

    function deleteHandleClick(hotel_id, name){
        if (!window.confirm(name + "を削除しますか？")){
            return;
        }
        getIdToken()
        .then(result => {
            const method = "DELETE";
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':result
            };
            const deleteLockUrl = API_GATEWAY + "/console/v1/contractors/"+location.state.contractorID+"/hotels/"+hotel_id;
            fetch(deleteLockUrl,
            {method:method, headers:headers})
            .then(res => {
                if (res.ok){
                    console.log("fetch実行完了")
                    deleteHotelScope(hotel_id);
                    // リストを再設定
                    setRedisp(!redisp);
                    return true;
                }else{
                    console.log("エラー")
                    alert("削除中にエラーが起きました")
                }})
                .catch(err => {
                    console.log("fetch失敗")
                    alert("削除中にエラーが起きました")
                })
        }).catch(error => {
            console.error("IDトークン取得失敗")
        });
        return false;
    }
    
    const getHotelLinks = () =>{
        return new Promise((resolve, reject) => {
            try{
                getIdToken()
                .then(result => {
                    const method = "GET";
                    const headers = {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization':result
                      };
                    var url = API_GATEWAY + "/console/v1/contractors/"+ location.state.contractorID +"/hotels";

                    fetch(url,
                    {method:method, headers:headers})
                    .then((res)=> res.json())
                      .then(data => {
                        console.log("ホテル一覧取得のfetch実行完了")

                        // ソート（文字辞書順）
                        data.hotels.sort(function(a,b){
                            if(a.name < b.name) return -1;
                            if(a.name > b.name) return 1;
                            return 0;
                        });

                        var list = [];
                        for(var i in data.hotels){
                            if (authType != 'admin' && !hotelList.includes(data.hotels[i].hotel_id)){
                                continue;
                            }
                            list.push(
                            <tr>
                               <td class="table_border_updown_thtd_left_number">
                                    {Number(i)+1}
                                </td>
                                <td class="table_border_updown_thtd_left">
                                    {data.hotels[i].name}
                                </td>
                                <td class="table_border_updown_thtd_right ">
                                    <button class="button2" onClick={doorlockHandleClick.bind(this, data.hotels[i])}>ドアロック</button>
                                </td>
                                <td class="table_border_updown_thtd_right ">
                                    {(authType == 'admin' || authType == 'installation') &&
                                    <button class="button2" onClick={gwHandleClick.bind(this, data.hotels[i])}>ゲートウェイ</button>}
                                    {authType == 'contractor' &&
                                    <button class="button2" onClick={commonareaHandleClick.bind(this, data.hotels[i])}>共用部</button>}
                                </td>
                                <td class="table_border_updown_thtd_right ">
                                    {(authType == 'admin' || authType == 'installation') &&
                                    <button class="button2" onClick={commonareaHandleClick.bind(this, data.hotels[i])}>共用部</button>}
                                </td>
                                
                                <td class="table_border_updown_thtd_right">
                                    {authType == 'admin' &&
                                    <button class="button2" onClick={dskHandleClick.bind(this, data.hotels[i])}>DSK</button>}
                                </td>
                                
                                <td class="table_border_updown_thtd_right">
                                    {authType == 'admin' &&
                                    <button class="button" style={{margin:"10px 0px 10px 40px"}} onClick={editHandleClick.bind(this, data.hotels[i])}>編集</button>}
                                    {authType == 'admin' &&
                                    <button class="button" id={data.hotels[i].hotel_id} onClick={deleteHandleClick.bind(this, data.hotels[i].hotel_id, data.hotels[i].name)}>削除</button>}
                                </td>
                                
                            </tr>);
                        }
                        // ページネーション用。リストの数が１ページ表示行数より多い時の処理。
                        if(list){
                            if(numOfLine < list.length){
                                const amari = list.length % numOfLine;
                                const blank_tr_count = numOfLine - amari;
                                if(amari != 0){
                                    for(var l=0;l<blank_tr_count;l++){
                                        list.push(<tr></tr>)
                                    }   
                                }
                            }
                        }
                        return resolve(list);
                      },[])
                      .catch(err => {
                        console.log("ホテル一覧取得のfetch失敗")
                        alert("ホテル一覧の取得に失敗しました")
                        return reject('ホテル一覧取得失敗')
                        })
                }).catch(error => {
                    console.error("IDトークン取得失敗："+error)
                    return reject('IDトークン取得失敗')
                })
            }
            catch(e){
                console.log('ホテル一覧取得失敗');
                return reject('ホテル一覧取得失敗')
            }  
        })
      
    }

    const history = useHistory();
    function doorlockHandleClick(hotelData){
        history.push({
          pathname: "/doorlock_list",
          state: { hotelName: hotelData.name, hotelID: hotelData.hotel_id, contractorID: location.state.contractorID}
      });
    }
    function gwHandleClick(hotelData){
        history.push({
          pathname: "/gw_list",
          state: { hotelName: hotelData.name, hotelID: hotelData.hotel_id, contractorID: location.state.contractorID}
      });
    }
    function commonareaHandleClick(hotelData){
        history.push({
          pathname: "/commonarea_list",
          state: { hotelName: hotelData.name, hotelID: hotelData.hotel_id, contractorID: location.state.contractorID}
      });
    }
    function dskHandleClick(hotelData){
        history.push({
          pathname: "/dsk_list",
          state: { hotelName: hotelData.name, hotelID: hotelData.hotel_id, contractorID: location.state.contractorID}
      });
    }
    function editHandleClick(hotelData){
        history.push({
          pathname: "/edit_hotel",
          state: {
              hotelName: hotelData.name,
              hotelID: hotelData.hotel_id,
              contractorID: location.state.contractorID,
              contractorName: location.state.contractorName
          }
      });
    }
    function registHandleClick(){
        history.push({
          pathname: "/regist_hotel",
          state: {
            contractorID: location.state.contractorID,
            contractorName: location.state.contractorName
          }
      });
    }
    async function isAdmin(){
        try{
            const user = await Auth.currentUserPoolUser();
            const attrs = await Auth.userAttributes(user);
            // 先にhotelListをsetして次のstateでauthをsetする
            attrs.forEach(function(attr){
                if (attr.Name == 'custom:hotels'){
                    const hotels = attr.Value.split(/\s/);
                    setHotelList(hotels);
                }
            });
            attrs.forEach(function(attr){
                if (attr.Name == 'custom:authority'){
                    setAuthType(attr.Value);
                }
            });
        }
        catch(e){
            Auth.signOut();
        }
    }
    async function deleteHotelScope(hotel_id){
        const [cognitoServiceProvider, user] = await getCognitoProvider();
        const result = await cognitoServiceProvider.describeResourceServer({
            Identifier: "jp.co.leadsecurity.webapi",
            UserPoolId: user.pool.userPoolId }).promise();
        let conf = result.ResourceServer;
        for(var i in conf.Scopes){
            if (conf.Scopes[i].ScopeDescription == hotel_id){
                conf.Scopes.splice(i, 1);
                break;
            }
        }
        const result2 = await cognitoServiceProvider.updateResourceServer(conf).promise();
    }


    if (loading){
        return (

        <div class="center_parents">
            <table class="overall_table">
                <tr>
                    <td>
                        <div class="title_text">ホテル一覧</div>
                    </td>
                    <td>
                        {authType == 'admin' && 
                        <button class="regist_button_right_2" onClick={registHandleClick.bind(this)}>新規登録</button>}
                    </td>
                </tr>
            </table>

            <hr color="#00B4C6" size="6" />
            <br />
            <table class="table_border overall_table">
                <tr>
                    <td>
                        <div>
                        <img src={loadimg}/>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        );
    }

    return (

        <div class="center_parents">
            <table class="overall_table">
                <tr>
                    <td>
                        <div class="title_text">ホテル一覧</div>
                    </td>
                    <td>
                        {authType == 'admin' && 
                        <button class="regist_button_right_2" onClick={registHandleClick.bind(this)}>新規登録</button>}
                    </td>
                </tr>
            </table>

            <hr color="#00B4C6" size="6" />
            <br />
            <table class="table_border overall_table">
                <tr>
                    <td class="table_border_updown_thtd_left_titlenumber">
                        No.
                    </td>
                    <td style={{width:250}}>
                        ホテル
                    </td>
                    <td class="table_button2_width">
                    </td>
                    <td class="table_button2_width">
                    </td>
                    <td class="table_button2_width">
                    </td>
                    <td class="table_button2_width">
                    </td>
                    <td class="">
                    </td>
                </tr>
                <Pagination
                dataList={links}
                col_count={7}
                numOfLine={numOfLine}
                />
                </table>

        </div>
    );
}

export default Hotellist;
