import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import '../App.css';
import testJson from '.././test/testJson/contractorTest.json';
import Amplify, {Auth} from 'aws-amplify';
import API_GATEWAY from "./hostname";
import {AuthState, onAuthUIStateChange} from "@aws-amplify/ui-components";
import Pagination from './pagination/Pagination.js';
import loadimg from "../images/loading.gif";

const getIdToken = () =>{
    return new Promise((resolve, reject) => {
        try{
            Auth.currentSession().then((data) => {
                return resolve(data.getIdToken().getJwtToken());
              });
        }
        catch(e){
            console.log('Not signed in');
            return reject('ID取得失敗')
        }  
    })
  
}
function Status(){
    const [links, setLinks] = useState([]);
    const [authType, setAuthType] = useState('');
    const [contractorList, setContractorList] = useState([]);
    const location = useLocation();
    const [redisp, setRedisp] = useState(false);
    const [countData, setCountData] = useState({});
    const numOfLine = 10;
    const [loading, setLoading] = useState(true);

    function handleSubmit(e) {
        e.preventDefault();
    }

    useEffect(() => {
        isAdmin();
        // サインイン完了後のコールバック
        onAuthUIStateChange((nextAuthState, authData) => {
            if (nextAuthState == AuthState.SignedIn){
                if (authType == ''){
                    isAdmin();
                }
            }
        });
    },[])
    useEffect(() => {
        if (authType != ''){
            // 契約者一覧を取得 => ホテル一覧取得 => デバイス一覧取得
            getIdToken()
            .then(result => {
                var token = result;
                getContractors(token)
                    .then(result => {
                        // 契約会社ソート（文字辞書順）
                        result.contractors.sort(function(a,b){
                            if(a.name < b.name) return -1;
                            if(a.name > b.name) return 1;
                            return 0;
                        });
                        var contractorsData = result;
                        var counts = [];
                        for(var i in contractorsData.contractors){
                            if (authType != 'admin' && !contractorList.includes(contractorsData.contractors[i].contractor_id)){
                                continue;
                            }
                            var onecont = {
                                contData: contractorsData.contractors[i],
                                hotels: 0,
                                commons: 0,
                                rooms: 0
                            }
                            counts.push(onecont);
                            getHotels(token, contractorsData.contractors[i].contractor_id)
                            .then(result => {
                                var hotelsData = result;
                                updateCounts(counts, hotelsData.cid, hotelsData.hotels.length, 0, 0);
                                for(var j in hotelsData.hotels){
                                    getDevices(token, hotelsData.hotels[j].hotel_id, hotelsData.cid)
                                        .then(result => {
                                            var roomCount = 0;
                                            var commonareaCount = 0;
                                            var devicesData = result;
                                            if("locks" in devicesData){
                                                roomCount = devicesData.locks.length;
                                            }
                                            if("access_groups" in devicesData){
                                                if(0 < devicesData.access_groups.length){
                                                    commonareaCount = 1;
                                                }
                                            }
                                            updateCounts(counts, devicesData.cid, 0, commonareaCount, roomCount);
                                            setCountData({
                                                update: 1,
                                                cont: counts
                                            });
                                        })
                                }
                            }
                            )
                        }
                        
                }).catch(error => {
                    console.error("契約者一覧取得失敗:"+error)
                })
            }).catch(error => {
                console.error("IDトークン取得失敗")
            });
        }
    },[authType, redisp])
    useEffect(() => {
        var list = [];
        for(var i in countData.cont){
            list[i] = (
                <tr class="tr_height_status">
                    <td class="table_border_updown_thtd_left_number">
                        {Number(i)+1}
                    </td>
                    <td class="table_border_updown_thtd_left">
                        <button class="link_style_button" onClick={handleClick.bind(this, countData.cont[i].contData)}>{countData.cont[i].contData.name}</button>
                    </td>
                    <td class="table_border_updown_thtd_right center">
                        {countData.cont[i].hotels}棟
                    </td>
                    <td class="table_border_updown_thtd_right center">
                        {countData.cont[i].rooms}室
                    </td>
                    <td class="table_border_updown_thtd_right center">
                        {countData.cont[i].commons}カ所
                    </td>
                </tr>)
        }
        // ページネーション用。リストの数が１ページ表示行数より多い時の処理。
        if(list){
            if(numOfLine < list.length){
                const amari = list.length % numOfLine;
                const blank_tr_count = numOfLine - amari;
                if(amari != 0){
                    for(var l=0;l<blank_tr_count;l++){
                        list.push(<tr></tr>)
                    }   
                }
            }
            // loading処理。listに何もなければ未取得判定。
            if(list.length != 0){
                setLoading(false)
            }
        }
        setLinks(list)
    },[countData])

    var handleChange = (event) => {
        let buff = event.target.value;
        // setState({roomNumber: buff});
    }

    const updateCounts = (countData, id, hotels, commons, rooms) => {
        for(var i in countData){
            if (countData[i].contData.contractor_id == id){
                countData[i].commons += commons;
                countData[i].rooms += rooms;
                if (hotels > 0){
                    countData[i].hotels = hotels;
                }
                break;
            }
        }
    }

    const getContractors = (token) =>{
        return new Promise((resolve, reject) => {
            try{
                const method = "GET";
                const headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':token
                    };
                fetch(API_GATEWAY + '/console/v1/contractors',
                {method:method, headers:headers})
                .then((res)=> res.json())
                    .then(data => {
                        console.log("契約企業一覧取得のfetch実行完了")
                        return resolve(data);
                    },[])
                    .catch(err => {
                        console.log("契約企業一覧取得のfetch失敗")
                        return reject('契約企業一覧取得失敗')
                    })
            }
            catch(e){
                console.log('契約者一覧取得失敗');
                return reject('契約者一覧取得失敗')
            }  
        })
      
    }
    const getHotels = (token, contractorID) =>{
        return new Promise((resolve, reject) => {
            try{
                const method = "GET";
                const headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':token
                    };
                const url = API_GATEWAY + "/console/v1/contractors/"+ contractorID +"/hotels";
                fetch(url,
                {method:method, headers:headers})
                .then((res)=> res.json())
                    .then(data => {
                        console.log("ホテル一覧取得のfetch実行完了")
                        data.cid = contractorID;
                        return resolve(data);
                    },[])
                    .catch(err => {
                        console.log("ホテル一覧取得のfetch失敗")
                        return reject('ホテル一覧取得失敗')
                    })
            }
            catch(e){
                console.log('ホテル一覧取得失敗');
                return reject('ホテル一覧取得失敗')
            }  
        })
      
    }
    const getDevices = (token, hotelID, cid) =>{
        return new Promise((resolve, reject) => {
            try{
                const method = "GET";
                const headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':token
                    };
                const url = API_GATEWAY + "/console/v1/devices/"+ hotelID;
                fetch(url,
                {method:method, headers:headers})
                .then((res)=> res.json())
                    .then(data => {
                        console.log("デバイス一覧取得のfetch実行完了")
                        data.cid = cid;
                        return resolve(data);
                    },[])
                    .catch(err => {
                        console.log("デバイス一覧取得のfetch失敗")
                        return reject('デバイス一覧取得失敗')
                    })
            }
            catch(e){
                console.log('デバイス一覧取得失敗');
                return reject('デバイス一覧取得失敗')
            }  
        })
      
    }
    const history = useHistory();

    function handleClick(contractorData){
        history.push({
          pathname: "/hotellist",
          state: {
              contractorName: contractorData.name,
              contractorID: contractorData.contractor_id
            }
      });
    }
    function editHandleClick(contractorData){
        history.push({
          pathname: "/edit_contractor",
          state: {
            contractorName: contractorData.name,
            contractorID: contractorData.contractor_id
          }      });
    }
    function deleteHandleClick(e: React.MouseEvent){
        e.preventDefault(); // フォーム送信を抑止
        const contractor_id = e.target.id;
        if (!window.confirm(contractor_id + "を削除しますか？")){
            return;
        }
        getIdToken()
        .then(result => {
            const method = "DELETE";
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':result
            };
            const deleteLockUrl = API_GATEWAY + "/console/v1/contractors/"+contractor_id;
            fetch(deleteLockUrl,
            {method:method, headers:headers})
            .then(res => {
                if (res.ok){
                    console.log("fetch実行完了")
                    // リストを再設定
                    setRedisp(!redisp);
                    return true;
                }else{
                    alert("削除中にエラーが起きました")
                }})
                .catch(err => {
                    console.log("fetch失敗")
                    alert("削除中にエラーが起きました")
                })
        }).catch(error => {
            console.error("IDトークン取得失敗")
        });
        return false;
    }
    async function isAdmin(){
        try{
            const user = await Auth.currentUserPoolUser();
            const attrs = await Auth.userAttributes(user);
            // 先にhotelListをsetして次のstateでauthをsetする
            attrs.forEach(function(attr){
                if (attr.Name == 'custom:contractors'){
                    const contractors = attr.Value.split(/\s/);
                    setContractorList(contractors);
                }
            });
            attrs.forEach(function(attr){
                if (attr.Name == 'custom:authority'){
                    setAuthType(attr.Value);
                }
            });
        }
        catch(e){
            /*
            現状、トークン無効エラーでのみここに来るので無条件でサインアウトする。
            今後条件判定が必要であれば、e.code=="NotAuthorizedException"などで見ればよい。
            {code: 'NotAuthorizedException', name: 'NotAuthorizedException', message: 'Access Token has been revoked'}
            */
            Auth.signOut();
        }
    }

    if (loading){
        return (

            <div class="center_parents">
            <div class="title_text">利用状況</div>
            <hr color="#00B4C6" size="6" />
            <br />
            <table class="table_border overall_table">
                <tr>
                    <td class="">
                    <img src={loadimg}/>
                    </td>
                </tr>
                </table>
        </div>
        );
    }
    return (
        
        <div class="center_parents">
            <div class="title_text">利用状況</div>
            <hr color="#00B4C6" size="6" />
            {/* ここから契約者一覧のリンクのリストが表示される */}
            <table class="table_border overall_table">
                <tr>
                    <td class="table_border_updown_thtd_left_titlenumber">
                        No.
                    </td>
                    <td class="status_table_left">
                        　契約会社
                    </td>
                    <td class="status_table_right center">
                        ホテル数
                    </td>
                    <td class="status_table_right center">
                        部屋数
                    </td>
                    <td class="status_table_right center">
                        共有部数
                    </td>
                </tr>
                <Pagination
                dataList={links}
                col_count={5}
                numOfLine={numOfLine}
                />
                </table>
        </div>
    );
}
export default Status;